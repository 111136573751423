@import '../../Scss/Mixins.scss';
@import '../../Scss/Variables.scss';

.container {
  @include container_s;

  position: relative;
  padding: 3.125rem 3.75rem 3.375rem 6.25rem;
  background-color: #151515;
  border-radius: 1rem;
  border: 1px solid #2A2B2E;
  margin-bottom: 5rem;
}

.wrapBtn {
  display: flex;
  column-gap: 40px;
}

.title {
  font-family: 'DIN Pro';
  font-size: 2.25rem;
  font-weight: 900;
  line-height: 1.222;
  margin-bottom: 0.4375rem;
}

.desc {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;

  margin-bottom: 4.125rem;
}


.fieldset {
  max-width: 23.75rem;
  flex-basis: 23.75rem;
  margin: 0;
  padding: 0;
  border: none;

  &>div:not(:last-child) {
    margin-bottom: 2.8125rem;
  }
}

.phoneWrap {
  position: relative;
}

.formTitle {
  padding: 0;
  margin-bottom: 1.5rem;

  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875;
  letter-spacing: 0.2px;
}

.inputGroup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1.875rem;

  &_check {
    & .input {
      border-color: #578561;
    }

    & .icon path {
      fill: #80E396;
    }
  }

  &_error {
    & .input {
      border-color: #855757;
    }

    & .icon path {
      fill: #CA3C3C;
    }
  }
}

.icon {
  position: absolute;
  height: auto;
  left: 1.25rem;
  top: 2.875rem;

  & path {
    transition: fill 0.3s ease;
  }
}

.btnViewPass {
  position: absolute;
  top: 2rem;
  right: 1.25rem;
  width: 2rem;
  height: 3rem;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.label {
  position: absolute;
  top: 0;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;

  margin-bottom: 1rem;
}

.input {
  width: 100%;
  padding: 1rem 3.75rem;
  max-height: 3.125rem;

  font-size: 1.25rem;
  line-height: 1.333;
  color: white;

  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: 0.2px;

  border-radius: 0.625rem;
  border: 1px solid #323232;
  background-color: transparent;
  border-radius: 1rem;
  outline: none;
  transition: border-color 0.3s ease;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.message {
  position: absolute;
  bottom: -1.25rem;
  font-size: 0.65rem;
  line-height: 1.125;
  letter-spacing: 0.2px;
}

.senBtnWrap {
  display: flex;
  align-items: center;
  position: absolute;
  top: 2rem;
  left: calc(100% + 1.375rem);
}

.sendSmsBtn {
  @include btn45;
  @include gradientViolet;

  background-size: 200% auto;
  transition: 0.3s;
  min-width: 10.75rem;
  border-radius: 0.625rem;
  padding: 0 1rem;
  flex-shrink: 0;
  background-image: linear-gradient(to right, #982EEB 0%, #7678D8 51%, #359AD2 100%);

  &:hover {
    background-position: right center;
  }

  &:disabled {
    opacity: 0.45;
    pointer-events: none;
    cursor: auto;
  }
}

.notification {
  @include fontStyle(0.75rem, 1.16);
  font-weight: 400;
  letter-spacing: 0.2px;
  margin-left: 1.25rem;
  color: rgba($color: #fff, $alpha: 0.6);
  min-width: 10rem;
  left: 100%;
}

.smsMessage {
  display: block;
  padding: 0;
  max-width: 6.25rem;
  text-align: left;

  font-size: 0.75rem;
  line-height: 1.1667;
  letter-spacing: 0.2px;
  border: none;
  background: none;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  flex-shrink: 0;
  transition: color 0.3s ease;

  &:hover {
    color: white;
  }
}

.btn {
  @include btn45;
  @include gradientViolet;

  display: block;
  position: relative;
  width: 100%;
  max-width: 17.5rem;
  margin: 3.25rem 0 0;
  padding: 1px;
  height: 45px;

  border-radius: 0.625rem;
  background-clip: padding-box;
  outline: none;
  color: white;
  cursor: pointer;

  &>span {
    @include gradientViolet;

    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: inherit;
  }

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }
}

.changeMethodBtn {
  display: block;
  margin-top: 2.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.166;
  letter-spacing: 0.2px;
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: rgba($color: #fff, $alpha: 0.7);
  }
}

.loginBtn {
  display: block;
  margin-top: 2.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.166;
  letter-spacing: 0.2px;
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: rgba($color: #fff, $alpha: 0.7);
  }
}


// блок с чекбоксами и регистр-ю через соцсети
.btnWrap {
  display: flex;
  max-width: 55.375rem;
  margin-top: 1.875rem;
}

.firstCheckbox,
.secondCheckbox {
  padding-top: 0.75rem;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.5);
}

.firstCheckbox {
  margin-right: 1.875rem;

  & label {
    display: block;
    max-width: 11.125rem;
  }
}

.secondCheckbox {
  & label {
    display: block;
    max-width: 14.5rem;
  }
}

.checkboxLabel {
  position: relative;
  padding-left: 2.125rem;
  cursor: pointer;

  &::before {
    @include pseudo;

    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid #323232;
    border-radius: 0.3125rem;
  }

  &::after {
    @include checkbox_check;
    background-image: linear-gradient(180deg, #25B444, #00FF19);
  }
}

input[type="checkbox"]:checked+.checkboxLabel {
  &::after {
    display: block;
  }
}


// Social
.socialWrap {
  flex-basis: 23.75rem;
  margin-left: auto;
}

.socialTitle {
  display: block;
  margin-bottom: 1.125rem;

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;
}

.socialList {
  display: flex;
  align-items: flex-start;
}

.socialItem {
  &:not(:last-child) {
    margin-right: 1.25rem;
  }
}

.socialBtn {
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  color: white;

  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.2px;
}

.backBtn {
  position: absolute;
  top: 3.125rem;
  right: 3.75rem;
  font-size: 0.75rem;
  line-height: 1.1667;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.2);
  transition: color 0.2s ease;

  &:hover {
    color: rgba(255, 255, 255, 0.7);

    & .btnArrow path {
      stroke-opacity: 0.7;
    }
  }
}

.btnArrow {
  margin-right: 0.625rem;

  & path {
    transition: stroke-opacity 0.2s ease;
  }
}

.socialLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  padding: 5px;
  margin-right: 0.625rem;
  background: linear-gradient(180deg, #6A27D9 0%, #2BA6B7 100%), #C4C4C4;
  border-radius: 3px;
}

@media(max-width: $desktop1440) {
  .container {
    width: $adaptiveWidth;
  }
}

@media(max-width: $tabletWidth) {
  .btnWrap {
    flex-wrap: wrap;
  }

  .socialWrap {
    flex-basis: 100%;
    width: 100%;
    margin-top: 2rem;
  }

  .notification {
    margin-left: auto;
    position: absolute;
    right: 0;
    bottom: -2rem;
  }
}

@media(max-width: $mobileWidth) {
  .container {
    padding: 2rem 1.5rem 3rem;
  }

  .title {
    font-size: 1.5rem;
    max-width: 14rem;
    padding-top: 1.5rem;
  }

  .desc {
    max-width: 18rem;
    margin-bottom: 2rem;
  }

  .fieldset {
    &>div:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  .socialList {
    display: block;
  }

  .socialItem {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .btnWrap {
    margin-top: 1.5rem;
  }

  .senBtnWrap {
    left: auto;
    right: 0;
    top: 1.875rem;
  }

  .sendSmsBtn {
    min-width: auto;
    padding: 0 0.375rem;
    width: 100%;
    max-width: 6.25rem;
    min-height: 3.0625rem;
    font-size: 0.625rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .backBtn {
    top: 1.75rem;
    right: auto;
    left: 1.5rem;
    font-size: 0.625rem;
  }

  .btnArrow {
    margin-right: 0.5rem;
  }

  .btn {
    margin-top: 2rem;
  }

  .notification {
    display: none;
  }

  .wrapBtn {
    flex-direction: column;
  }

  .loginBtn {
    margin-top: 0.5rem;
  }
}