@import '../../Scss/Mixins.scss';
@import '../../Scss/Variables.scss';


.popup, .popupRel {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  z-index: 99;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 1.5rem;

  @media(max-width: 991px) {
    align-items: flex-start;
  }

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.6);

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #31AFF5, #7128E8);
    border-radius: 2px;
  }

  &::-webkit-scrollbar {
    border-radius: 2px;
    width: 2px;
    background-color: #2A2B2E;
  }

  & > div {
    margin-top: auto;
    margin-bottom: auto;
  }
}

//.popupRel {
//  width: calc(100% - 19rem);
//  left: 19rem;
//}

// Default styles for Popup (verify popup)
.wrapper {
  position: relative;
  max-width: 49rem;
  width: 100%;
  padding: 2.5rem;
  padding-left: 4.4375rem;
  background-color: #151515;
  border-radius: 1rem;
  border: 1px solid #2A2B2E;
}

.title {
  position: relative;
  padding-left: 3rem;
  font-family: 'DIN Pro';
  font-size: 2rem;
  font-weight: 900;
  line-height: 1.222;

  &::after {
    @include pseudo;

    width: 1.5rem;
    height: 1.75rem;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('../../../images/svg/checkMailPopup.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;
}

.closeBtn {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  width: 1.875rem;
  height: 1.875rem;
  padding: 0;

  cursor: pointer;
  background-color: transparent;
  border: none;

  @include pseudo-cross-btn($r: 45deg);
}

@media(max-width: $tabletWidth) {
  .wrapper {
    padding: 2rem 1.5rem;
  }

  .title {
    max-width: 23rem;
    font-size: 1.5rem;
  }

  .popupRel {
    padding: 0;
    //width: 100%;
    //left: 0;
  }
}
