@import '../Scss/Mixins.scss';
@import '../Scss/Variables.scss';

.title {
  @include title_main;
  margin-bottom: 1.25rem;
}

.label {
  font-size: 1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 1.188rem;
}
.underlineTitle {
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
}
.insuranceWrapper {
  margin-top: 1rem;
  max-width: 350px;
  min-width: 300px;
  background-color: #151515;
  border-radius: 15px;
  padding: 25px;
  position: relative;
}

.pastInsurances {
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.fio::first-letter {
  text-transform: uppercase;
}
.mainTitle {
  @include title_main;
  margin-bottom: 1.25rem;
}
.foot {
  margin-top: auto;
}
.logoWrap {
  max-width: 100px;
  margin-bottom: 2rem;
}
.logoInsuranceWrap {
  max-width: 100px;
  margin-bottom: 1rem;
  margin-left: auto;
}
.name {
  font-family: Gilroy;
  font-size: 24px;
  font-weight: 900;
  line-height: 30.29px;
}
.nameSmall {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 900;
  line-height: 28px;
  white-space: nowrap;
}
.day {
  font-family: Gilroy;
  font-size: 13.5px;
  font-weight: 500;
  line-height: 16.98px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.title {
  font-family: Gilroy;
  margin-top: 0.8rem;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.81px;
}
.subtitle {
  font-family: Gilroy;
  margin-top: 0.8rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.98px;
}
.insurancePrice {
  font-family: Gilroy;
  margin-top: 0.8rem;
  font-size: 14px;
  font-weight: 700;
  line-height: 17.33px;
}
.footnote {
  font-family: Gilroy;
  margin-top: 0.8rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}
.number {
  font-family: Gilroy;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.71px;
  text-align: right;
}
.top {
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 100%;
  height: auto;
}
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4.125rem 1.5rem;
}
.insurancesList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4.125rem 1.5rem;
}
.insurancesWrap {
  margin-top: 10rem;
}
.itemLabel {
  width: 100%;
  font-size: 0.625rem;
  font-weight: 400;
}
.price {
  display: flex;
  justify-content: space-between;
}
.info {
  margin-bottom: 1.1rem;
  font-size: 0.875rem;
  font-weight: 700;
  margin-left: auto;
}
.btn {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0s ease;
  @include btn40;
  @include btnEventPage;
  @include gradientViolet;
  background: url('../../images/svg/insurance_icon.svg') 2.4rem 50%,
    linear-gradient(267.53deg, #359ad2 -0.27%, #3a2eeb 100%);
  background-repeat: no-repeat;
  bottom: -1.25rem;
  transform: translateX(-50%);
  max-width: 17.25rem;
  width: 100%;
  font-size: 0.75rem;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem rgba(53, 154, 210, 0.75);
}
.btn:hover {
  background: url('../../images/svg/insurance_icon.svg') 2.4rem 50%,
    linear-gradient(267.53deg, #c7252b, #3a2eeb);
  background-repeat: no-repeat;
}
.btnInsurance {
  margin-top: 2rem;
  @include btn40;
  background-color: #e53d44;
  max-width: 340px;
  width: 100%;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  text-align: center;
  margin-bottom: 1rem;
}
.linkInsurance {
  text-decoration: underline;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  margin-bottom: 1rem;
}
.linkWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.insuranceCard {
  margin-top: 1rem;
  max-width: 350px;
  min-width: 300px;
  background-color: #0c0c0c;
  border-radius: 10px;
  padding: 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.insuranceSmallCard {
  margin-top: 1rem;
  background-color: #0c0c0c;
  border-radius: 10px;
  padding: 25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: $desktop1200) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
  .insurancesList {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: $mobileWidth) {
  .list {
    display: block;
  }
  .insurancesList {
    display: block;
  }
  .insuranceWrapper {
    margin-top: 2rem;
  }
  .nameSmall {
    font-size: 23px;
  }
}
