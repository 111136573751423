@import '../../Scss/Mixins.scss';
@import '../../Scss/Variables.scss';

.item {
  position: relative;
  flex-basis: 23.75rem;
  border-radius: 0.938rem;
  background-repeat: no-repeat;
  background-position: right;
}

.itemWrapper {
  position: relative;
  padding: 1.563rem;
  display: flex;
  justify-content: space-between;
  width: 85%;
  min-height: 16.75rem;
  background-color: #151515;
  border-radius: 0.938rem;
  z-index: 10;
}

.logo {
  margin-bottom: 2.188rem;
}

.info {
  margin-bottom: 1.1rem;
  font-size: 14px;
  font-weight: 700;
  // margin-left: auto;
}

.infoTicket {
  position: absolute;
  top: 1.563rem;
  right: 1.563rem;
}

.itemLabel {
  display: block;
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  margin-bottom: 5px;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  font-size: 0;
  border-radius: 0.938rem;
  width: 50%;
  height: 100%;
  background-image: linear-gradient(180deg, #9F76F7, #359bd2);
}

.coverImg {
  border-radius: 0.938rem;
  mix-blend-mode: normal;
  height: inherit;
  // width: 140px;
  object-fit: cover;
  object-position: 30%;
}

.btn, .btnAwait {
  @include btn40;
  @include btnEventPage;
  @include gradientViolet;

  position: absolute;
  left: 50%;
  bottom: -1.25rem;
  transform: translateX(-50%);
  max-width: 17.25rem;
  width: 100%;
  font-size: 0.75rem;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem rgba(53,154,210,0.75);
}

.btnAwait {
  background-image: linear-gradient(90deg, #EE8D1B, #C7252B);
  cursor: default;
}

.btnRegNoHover, .btnRegHover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.0s ease;
}

.btnRegHover {
  opacity: 0;
}

.btnRegistered {
  background: url('../../../images/svg/icon-check.svg') 2.4rem 50%, linear-gradient(267.53deg, #359AD2 -0.27%, #3DEB2E 100%);
  background-repeat: no-repeat;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem rgba(185, 248, 105, 0.75);
  overflow: hidden;
  transition: all 0.25s ease;

  &:hover {
    background: url('../../../images/svg/icon-check.svg') 2.4rem 50%, linear-gradient(267.53deg, #982EEB -0.27%, #359AD2 100%);
    background-repeat: no-repeat;

    .btnRegNoHover {
      opacity: 0;
    }

    .btnRegHover {
      opacity: 1;
    }
  }
}

.btnPast {
  background: url('../../../images/svg/icon-check.svg') 2.4rem 50%, linear-gradient(267.53deg, #359AD2 -0.27%, #eb2ec2 100%);
  background-repeat: no-repeat;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem rgba(185, 248, 105, 0.75);
  overflow: hidden;
  transition: all 0.25s ease;
}

.notFoundMessage {
  @include fontStyle(1.25rem, 1.2);
  margin-bottom: 2rem;
}

.test {
  display: flex;
  flex-direction: row;
  // position: relative;
}

.sumWrap {
  justify-content: space-between;
}

.collumnLeft{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
}

.icon {
   //position: absolute;
  margin-right: 0.68rem;
  top: 50%;
  transform: translateY(-25%);
  left: 1.25rem;
  transition: transform 0.3s ease;
}

@media(max-width: $desktop1200) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(max-width: $tabletWidth) {
  .notExistBlock {
    margin-bottom: 5rem;
  }
}


@media(max-width: $mobileWidth) {
  .offersWrap {
    margin-bottom: 1.625rem;
  }

  .titleWrap {
    display: block;
    padding-bottom: 2rem;
  }

  .link {
    margin-top: 1.5rem;
  }

  .cover {
    display: none;
  }

  .list {
    display: block;
  }

  .item {
    width: 100%;
    flex-basis: auto;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  .itemWrapper {
    width: auto;
  }

  .btn {
    max-width: 15rem;
  }

  .btnRegistered {
    background-position: 1.125rem, 0;
  }

  .notExistBlock {
    margin-bottom: 3rem;
  }
}