html {
    --button-event-color: #E63D44;
    --cards-event-background-color: #212121;
    --cards-event-background-image: linear-gradient(180deg, #8F65E9, #4845D3);
    --cards-event-background-image-card-main: url('../../images/event/format/formatBack-min.png'), url('../../images/event/format/formatSecBack.svg');
}

// Colors
$white: #ffffff;
$black: #000000;
$black-medium: #212121; // Used for card backgrounds, selects
$grey-dark: #333333;    // Used for borders
$grey-medium: #aaa;     // Used for labels, secondary text
$grey-light: #cccccc;   // Lighter grey if needed
$blue-light: #5c9ded;   // Used for focus states

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
$partners-border-radius: 3px;
$borderRadius: 6px;
$primaryColor: #1a191f;

$border-top-block: #151515;

$footer_height: 23.625rem;

$desktop1440: 1439px; // or 90 rem
$desktop1200: 1199px;
$tabletWidth: 991px;
$mobileWidth: 768px;
$smartPhoneWidth: 480px;
$adaptiveWidth: calc(100% - 3rem);