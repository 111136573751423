@import 'components/Scss/Mixins';
@import 'components/Scss/Variables';

.datepicker {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 23.75rem;

  &Error {
    & .datepickerInput {
      border-color: #855757;
    }

    & .icon path {
      fill: #ca3c3c;
    }
  }

  &Disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.label {
  @include fontStyle(0.75rem, 1.2);
  margin-bottom: 1rem;
}

.pseudoWrap {
  position: relative;
}

.icon {
  pointer-events: none;
  position: absolute;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 1.25rem;

  & path {
    transition: fill 0.3s ease;
  }
}

.datepickerInput {
  width: 100%;
  min-height: 3.125rem;
  border: 1px solid #323232;
  border-radius: 1rem;
  padding: 0.5rem;
  padding-left: 3.75rem;
  background-color: transparent;
  color: white;
  @include fontStyle(1rem, 1.125);
  transition: border-color 0.3s ease;

  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
    position: absolute;
    left: 0;
    background-color: none;
  }
}

.message {
  position: absolute;
  bottom: -1.8rem;
  font-size: 0.75rem;
  line-height: 1.125;
  letter-spacing: 0.2px;

  color: #ca3c3c;
}

@media (max-width: $mobileWidth) {
  .datepicker {
    max-width: none;
  }
}
