.carousel {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
}

.carouselSlideVisible,
.carouselSlideActive,
.carouselSlideNext,
.carouselSlidePrev {
    text-align: unset;
}

.carouselWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
}

.carouselSlide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
}

.carouselPointerEvents {
    touch-action: pan-y;
}