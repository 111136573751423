@import '../../Scss/Mixins.scss';
@import '../../Scss/Variables.scss';
.popup {
  position: fixed;
  width: 100%;
  max-height: 100vh;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  z-index: 99;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem 1.5rem;

  @media (max-width: 991px) {
    align-items: flex-start;
    max-height: unset;
  }

  overflow-y: scroll;

  background-color: rgba(0, 0, 0, 0.6);

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #31aff5, #7128e8);
    border-radius: 2px;
  }

  &::-webkit-scrollbar {
    border-radius: 2px;
    width: 2px;
    background-color: #2a2b2e;
  }

  & > div {
    margin-top: auto;
    margin-bottom: auto;
  }
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 65.4375rem;
  padding: 40px;
  padding-top: 100px;
  border-radius: 0.625rem;
  background-color: #151515;
  /*  overflow-x: auto; */
}

.popupCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.625rem;
  height: 3.125rem;
  background-color: #222222;
  border: none;
  border-top-right-radius: 0.625rem;
  cursor: pointer;

  &::after,
  &::before {
    @include pseudo;

    width: 1.5rem;
    height: 0.125rem;
    background-color: #5e5e5e;
    border-radius: 0.125rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.formMerch {
  margin-bottom: 5rem;
}

.title {
  margin-bottom: 2rem;

  font-size: 2rem;
  font-weight: 900;
  text-transform: uppercase;
}

.row {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

.inputGroup {
  max-width: 21.875rem;
}

.inputWrap {
  margin-top: 2rem;
}

.inputGroupTextarea {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 45.625rem;
  padding-top: 1.875rem;
  //margin-bottom: 1.563rem;

  &_check {
    & .input {
      border-color: #578561;
    }

    & .icon path {
      fill: #80e396;
    }
  }

  &_error {
    & .input {
      border-color: #855757;
    }

    & .icon path {
      fill: #ca3c3c;
    }
  }
}

.label {
  position: absolute;
  top: 0;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;

  margin-bottom: 1rem;
}

.iconTextarea {
  position: absolute;
  height: auto;
  left: 1.25rem;
  top: 2.875rem;

  & path {
    transition: fill 0.3s ease;
  }
}

.textarea {
  width: 45.625rem;
  min-height: 10.625rem;
  resize: none;

  position: relative;
  padding: 1rem 3.75rem;
  padding-left: 4rem;
  max-height: 3.125rem;

  color: white;

  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: 0.2px;

  border: 1px solid #323232;
  background-color: transparent;
  border-radius: 1rem;
  outline: none;
  transition: border-color 0.3s ease;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.btn {
  @include btn40;
  margin-right: 1.875rem;
  width: 17.5rem;

  &:first-child {
    margin-bottom: 1rem;
  }

  @include btnEventPage;

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}

.firstCheckbox,
.secondCheckbox {
  padding-top: 0.75rem;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.5);
}

.firstCheckbox {
  //margin-right: 1.875rem;

  & label {
    display: block;
    //max-width: 11.125rem;
  }
}

.secondCheckbox {
  & label {
    display: block;
    //max-width: 14.5rem;
  }
}

.checkboxLabel {
  position: relative;
  padding-left: 2.125rem;
  width: 100%;
  cursor: pointer;

  &::before {
    @include pseudo;

    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid #323232;
    border-radius: 0.3125rem;
  }

  &::after {
    @include pseudo;

    display: none;
    width: 1.125rem;
    height: 1.125rem;
    top: 0.16rem;
    left: 0.19rem;
    border-radius: 0.3125rem;
    background: linear-gradient(180deg, #25b444 0%, #00ff19 100%);
  }
}

input[type='checkbox']:checked + .checkboxLabel {
  &::after {
    display: block;
  }
}

.btnWrap {
  padding-top: 1.875rem;

  display: flex;
  justify-content: space-between;
}

@media (max-width: $tabletWidth) {
  .inputGroup {
    width: 100%;
    max-width: 23.75rem;
  }
  .wrapper {
    justify-content: center;
    height: 100%;
  }
  .inputGroupCity {
    max-width: 23.75rem;
  }

  .inputGroupTextarea {
    max-width: 100%;
  }

  .textarea {
    width: 100%;
  }
}

@media (max-width: $mobileWidth) {
  .popup {
    position: absolute;
  }
  .title {
    margin-bottom: 0.6rem;

    font-size: 1rem;
  }
  .row {
    flex-direction: column;
  }
  .inputGroup {
    max-width: 100%;
  }

  .btnWrap {
    padding-top: 0;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .btn {
    margin-right: auto;
    width: 100%;
    max-width: 21.313rem;
  }
}
