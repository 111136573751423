@import '../../Scss/Mixins.scss';
@import '../../Scss/Variables.scss';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 65.4375rem;
  border-radius: 15px;
  background-color: #151515;
  padding: 35px;
  max-width: 908px;
  height: 100%;
  min-height: 583px;
  max-height: 800px;
}

.insuranceCard {
  margin-top: 2rem;
}
.name {
  font-family: Gilroy;
  font-size: 24px;
  font-weight: 900;
  line-height: 30.29px;
}
.insuranceTitle {
  font-family: Gilroy;
  margin-top: 0.8rem;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.81px;
}
.subtitle {
  font-family: Gilroy;
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.98px;
}
.footnote {
  font-family: Gilroy;
  margin-top: 0.8rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
}
.title {
  font-family: DIN Pro;
  font-size: 36px;
  font-weight: 900;
  line-height: 43.74px;
  text-align: left;
}
.text {
  font-size: 36px;
  font-weight: 900;
  line-height: 43.74px;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.city {
  margin-top: 1rem;
}
.firstCheckbox {
  padding-top: 0.75rem;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
}
.btnInsurance {
  margin-top: 2rem;
  @include btn40;
  background-color: #e53d44;
  max-width: 340px;
  width: 100%;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.41px;
  text-align: center;
  margin-bottom: 1rem;
}
.btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.firstCheckbox {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.5px;
  text-align: left;
  color: #fff;
  margin-right: 1.875rem;

  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.5px;

  & label {
    display: block;
  }
}
.checkboxLabel {
  position: relative;
  padding-left: 2.125rem;
  cursor: pointer;

  &::before {
    @include pseudo;

    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid white;
    border-radius: 0.3125rem;
  }

  &::after {
    @include checkbox_check;
    background-image: linear-gradient(180deg, #25b444, #00ff19);
  }
}

input[type='checkbox']:checked + .checkboxLabel {
  &::after {
    display: block;
  }
}

.legals {
  text-decoration: underline;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.5px;
}

.description {
  font-size: 12px;
  font-weight: 700;
  line-height: 14.86px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-bottom: 1rem;
}
.selectsWrapper {
  margin-top: 2rem;
}

.popupCloseBtn {
  position: absolute;
  top: 35px;
  right: 55px;
  width: 40px;
  height: 40px;
  background-color: #000000;
  border: none;
  border-radius: 100px;
  cursor: pointer;

  &::after,
  &::before {
    @include pseudo;

    width: 1.2rem;
    height: 0.125rem;
    background-color: #fff;
    border-radius: 0.125rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}
@media (max-width: $tabletWidth) {
  .wrapper {
    min-height: 850px;
    max-height: 850px;
  }
}

@media (max-width: $mobileWidth) {
  .title {
    font-size: 1.25rem;
  }
  .popupCloseBtn {
    top: 15px;
    right: 15px;
  }
  .popup {
    padding: 0;
  }
}
