@import '../Scss/Mixins.scss';
@import '../Scss/Variables.scss';

.footer {
  padding: 1.5rem 0 5rem;
  border-top: 1px solid #151515;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1.875rem;
  min-height: 2.875rem;
}

.title {
  font-family: 'DIN Pro';
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1875;
}

.cabinet_link {
  @include btn30;
  @include gradientViolet;

  align-self: flex-end;
}

.wrapper {
  display: flex;
}

.links_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.875rem;
  column-gap: 5rem;
  margin-right: auto;
}

.links_item {
  font-size: 1rem;
  line-height: 1;
  letter-spacing: -0.5px;
}

.socials {
  display: flex;
  flex-wrap: wrap;
  /*  justify-content: space-between; */
  column-gap: 20px;
  max-width: 6.625rem;
  width: 100%;
  height: 0;
}

.copyright_block {
  align-self: flex-end;
  text-align: right;
  max-width: 16.875rem;
}

.copyright {
  margin-top: 2.25rem;
  font-size: 0.875rem;
  line-height: 1.7;
  letter-spacing: -0.5px;
}

@media (max-width: 991px) {
  .header {
    flex-direction: column;
  }

  .cabinet_link {
    margin-top: 2rem;
    align-self: flex-start;
  }

  .wrapper {
    display: block;
  }

  .links_list {
    grid-template-columns: repeat(auto-fit, minmax(6.25rem, 1fr));
    gap: 1.5rem;
  }

  .links_item {
    font-size: 0.75rem;
  }

  .copyright_block {
    margin-top: 3rem;
    max-width: none;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .footer {
    padding-bottom: 3rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .cabinet_link {
    margin-top: 1.25rem;
  }

  .copyright {
    font-size: 0.625rem;
    margin-top: 1.5rem;
  }

  .copyright_block {
    margin-top: 2rem;

    & img {
      width: 4rem;
    }
  }
}
