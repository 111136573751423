@import '../../Scss/Mixins.scss';
@import '../../Scss/Variables.scss';

.section {
  margin-bottom: 5rem;
  position: relative;
}

.title_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.title {
  @include title_main;
}

.titleLink {
  @include btn30;
  @include gradientViolet;
  min-width: 14rem;
}

.main {
  position: relative;
  width: 100%;
  margin-right: 2rem;
  margin-bottom: 5rem;
  overflow-x: hidden;
  flex-shrink: 0;
  padding-top: 1.875rem;
  border-top: 1px solid #151515;
}

.container {
  width: 100%;
  position: relative;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}

// Slide
.slide {
  position: relative;
  width: 17.5rem;
  flex-shrink: 0;
  border-radius: 0.25rem;

  &:hover {
    background-color: #ffffff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &:hover .slideText,
  &:hover .price {
    color: #000000;
  }

  &:hover .priceSale {
    color: #ED1C24;
  }
}

.imageWrap {
  width: 100%;
  height: 17.5rem;
  overflow: hidden;
  border-radius: 1rem;
}

.slideImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.slideContent {
  display: flex;
  min-height: 29%;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 1rem;
  padding-bottom: 1rem;
  color: var(--colorText);
}

.category {
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #7F828A;
  text-transform: uppercase;
}

.slideText {
  display: block;
  max-width: 15rem;
  margin-top: 1.25rem;

  font-family: 'DIN Pro';
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.222;
}

.priceContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.price {
  display: block;
  margin-top: 0.625rem;
  font-size: 1.5rem;
  line-height: 1.166;

  &._sale {
    color: #ED1C24;
    margin-right: 0.7rem;
  }
}

.priceOld {
  font-size: 1rem;
  font-weight: 500;
  color: #AEB7C1;
  text-decoration: line-through;
}

.link {
  text-decoration: underline;
  color: black;
}

.nav {
  @include navBlock;
  right: auto;
  left: 1rem;
  width: calc(100% - 2rem);
  top: 47%;
  justify-content: space-between;
  flex-direction: row;
  z-index: 2;
}

.btnPrev {
  @include btnSliderPrev;
  // background-color: #F2F3F6;
  // border-radius: 0.25rem;

  // &::after {
  //   width: 0.5625rem;
  //   height: 0.9375rem;
  //   background: url('/images/svg/arrow-right-black.svg') no-repeat;
  //   transform: translate(-50%, -50%) rotate(180deg);
  // }
}

.btnNext {
  @include btnSliderNext;
  // background-color: #F2F3F6;
  // border-radius: 0.25rem;

  // &::after {
  //   width: 0.5625rem;
  //   height: 0.9375rem;
  //   background: url('/images/svg/arrow-right-black.svg') no-repeat;
  // }
}

.btnPrev,
.btnNext {
  width: 3.125rem;
  height: 3.125rem;

  @media (max-width: $desktop1440) {
    display: block;
  }
}

@media (max-width: $mobileWidth) {
  .nav {
    display: none;
  }

  .slide {
    min-height: fit-content;
  }

  .titleLink {
    min-width: auto;
  }

  .imageWrap {
    height: 10.5rem;
  }

  .price {
    font-size: 1.3rem;

    &._sale {
      margin-right: 0.5rem;
      font-size: 1.2rem;
    }
  }

  .priceOld {
    font-size: 0.6rem;
  }

  .slideContent {
    padding: 0 0.5rem;
    min-height: 35%;
  }

  .slideText {
    margin-top: 0.6rem;
  }
}