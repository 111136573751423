.optCard-select {
  &-container {
    margin-top: auto;

    & .optCard-select__control {
      border: 1px solid #AEB7C1;
      border-radius: 0.3125rem;
      min-height: 1.875rem;
      padding: 0.5rem;
    }

    & .optCard-select__menu {
      top: auto; bottom: 0%;
      background-color: #0c0c0c;
      margin-bottom: 0.25rem;
      margin-top: 0.25rem;
      padding: 0.5rem;
    }

    & .optCard-select__dropdown-indicator {
      width: 0.875rem;
      & svg path {
        fill: #AEB7C1;
      }
    }

    & .optCard-select__placeholder {
      color: inherit;
      font-size: 0.625rem;
    }

    & .optCard-select__single-value, & .optCard-select__option {
      color: white;
    }

    & .optCard-select__option {
      padding: 0.2rem 0.5rem;

      &--is-focused {
        background-color: rgba(255, 255, 255, 0.205);
      }
    }
  }
}