@import '../../Scss/Mixins.scss';
@import '../../Scss/Variables.scss';

.container {
  position: relative;
  padding: 3.125rem 3.75rem 3.375rem 6.25rem;
  margin-bottom: 3rem;
  background-color: #151515;
  border-radius: 1rem;
  border: 1px solid #2A2B2E;
}

.title {
  font-family: 'DIN Pro';
  font-size: 2.25rem;
  font-weight: 900;
  line-height: 1.222;
  margin-bottom: 3.375rem;
}

.fieldset {
  max-width: 50rem;
  display: flex;
  justify-content: space-between;
  margin: 0 0 2.625rem;
  padding: 0;
  border: none;
}

.btn {
  @include btn45;
  @include gradientViolet;

  display: block;
  position: relative;
  max-width: 17.5rem;
  width: 100%;
  padding: 1px;
  min-height: 2.8125rem;

  border-radius: 0.625rem;
  background-clip: padding-box;
  outline: none;
  color: white;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: auto;
  }
}

.links {
  display: flex;
  align-items: center;
  margin: 1.75rem 0 4.375rem;
}

.link {
  font-family: 'Gilroy';
  font-weight: 400;
  text-align: left;
  font-size: 0.75rem;
  line-height: 1.1667;
  letter-spacing: 0.2px;
  color: white;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:not(:last-child) {
    margin-right: 2.5rem;
  }

  &:disabled {
    color: rgba($color: #fff, $alpha: 0.5);
    pointer-events: none;
  }
}


.socialTitle {
  display: block;
  margin-bottom: 1rem;

  font-size: 0.75rem;
  line-height: 1.1667;
  font-weight: 400;
  letter-spacing: 0.2px;
}

.socialList {
  display: flex;
  align-items: flex-start;
}

.socialItem {
  &:not(:last-child) {
    margin-right: 2.5rem;
  }
}

.socialBtn {
  @include fontStyle(0.875rem, 1.42);

  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
  background: none;
  border: none;
  color: white;
}

.backBtn {
  position: absolute;
  top: 3.125rem;
  right: 3.75rem;
  padding-left: 1.125rem;
  font-size: 0.75rem;
  line-height: 1.1667;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.2);
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: rgba(255, 255, 255, 0.7);

    & .btnArrow path {
      stroke-opacity: 0.7;
    }
  }
}

.btnArrow {
  margin-right: 0.625rem;

  & path {
    transition: stroke-opacity 0.2s ease;
  }
}

.socialLogo {
  width: 1.5rem;
  height: 1.5rem;
  padding: 5px;
  margin-right: 0.625rem;
  background: linear-gradient(180deg, #6A27D9 0%, #2BA6B7 100%), #C4C4C4;
  border-radius: 3px;
}

.btnWrapper {
  display: flex;
  position: relative;
}

.confirmBtn {
  @include btn45;
  @include gradientViolet;
  border-radius: 0.625rem;
  width: 100%;
  max-width: 17.5rem;
}

@media(max-width: $tabletWidth) {
  .fieldset {
    display: block;
    margin-bottom: 2rem;

    & > * {
      &:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
  }

  .links {
    margin-bottom: 3rem;
  }
}

@media(max-width: $mobileWidth) {
  .container {
    padding: 2rem 1.5rem 3rem;
  }

  .title {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.5rem;
  }

  .link {
    &:not(:last-child) {
      margin-bottom: 1rem;
      margin-right: 0;
    }
  }

  .socialList {
    display: block;
  }

  .socialItem {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .backBtn {
    top: 2.75rem;
    right: 1.5rem;
    font-size: 0.625rem;
  }

  .btnArrow {
    margin-right: 0.5rem;
  }
}
