@import '../../Scss/Mixins.scss';
@import '../../Scss/Variables.scss';

.item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 1.875rem 1.25rem 2.5rem;
  border-bottom: 0.0625rem solid rgba(68, 68, 68, 0.4);
}
.inputFile {
  display: none;
}
.datepicker {
  width: 100%;
  max-width: 334px;
}

.insuranceSelect {
  font-size: 14px;
  padding: 0.5rem 0.5rem;
  border-radius: 0.625rem;
  outline: none;
  box-shadow: none;
  border: 1px solid #3d3d3d;
  margin-top: 5px;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.linkInsurance {
  text-decoration: underline;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  margin-bottom: 1rem;
}
.labelFile {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;
  cursor: pointer;
}

.filter {
  font-size: 0.75rem;
  font-weight: 400;
  display: block;
  margin-bottom: 1rem;
  contain: layout;
}

.messageError {
  position: absolute;
  bottom: -1.25rem;
  left: 0;
  @include fontStyle(0.75rem, 1.125);
  letter-spacing: 0.2px;
}

.wrapperForm {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
}

.selector {
  position: relative;

  &Error {
    > :global(div.ticket-select__control) {
      border-color: #855757;
    }

    > :global(div.cluster-select__control) {
      border-color: #855757;
    }

    > :global(div.basketsize-select__control) {
      border-color: #855757;
    }
  }

  &Disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.selectorError {
  color: #ca3c3c;
}
.filter {
  color: #fff;
}

.textFile {
  display: block;
  border: 1px solid #323232;
  border-radius: 1rem;
  text-align: center;
  white-space: nowrap;
  min-width: 294px;
  max-width: 23.75rem;

  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;

  padding: 1rem 3.75rem;
  max-height: 3.125rem;

  color: #323232;
}

.textFileInsurance {
  display: block;
  border: 1px solid #323232;
  border-radius: 1rem;
  text-align: center;
  white-space: nowrap;
  min-width: 334px;
  width: 100%;
  max-width: 334px;

  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;

  padding: 1rem 3.75rem;
  max-height: 3.125rem;

  color: #323232;
}
.cluster {
  max-width: 22.75rem;
  min-width: 292px;
}

.notes {
  @include fontStyle(0.75rem, 1.5);
  column-gap: 4px;
  display: list-item;
}

.notesWrapper {
  /* margin-top: 2rem;
  margin-bottom: 2rem; */
  list-style-type: disc;
}

.registered {
  color: #fff;
  opacity: 1;
}

.labelFileError {
  border-color: #855757;
}

.logoWrap {
  margin-bottom: auto;
  width: 6rem;
  flex-shrink: 0;
  margin-right: 1.5rem;
  border-radius: 0.5rem;
  overflow: hidden;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.infoBlock {
  flex-grow: 1;
  padding-left: 1.5rem;
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}

.row {
  display: flex;
  margin-top: 30px;
  gap: 30px;
}

//////////// INSIDE ITEM
.insideItem {
  display: flex;
  font-size: 0.625rem;
  line-height: 1.2;

  & .count {
    margin-bottom: 1rem;
  }
}

.insideItemInsurance {
  padding-bottom: 0.4rem;
}

.insideItemAdd {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
}

.location {
  width: 6rem;
  margin-right: auto;
}

.locationInsurance {
  margin-right: auto;
}

.insideItem > * {
  padding-top: 1.875rem;
}

.insideItem:last-child > * {
  padding-bottom: 1.25rem;
}

.format {
  margin-right: auto;
  width: 5.5rem;
}

.count {
  margin-right: 1.5rem;
  text-align: right;
}

.countBtn {
  background-color: transparent;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  border: none;
  color: white;
  cursor: pointer;
}

.costWrap {
  padding-left: 1.25rem;
  width: 8.25rem;
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}

.text {
  margin-top: 0.75rem;
  position: relative;
}

.countText {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    width: 20%;
    text-align: center;
  }
}

.cost {
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1.222;
  margin-top: 0.125rem;
}

.totalCost {
  font-size: 1rem;
  white-space: nowrap;
}

.checkbox {
  display: none;
}

.checkbox:checked + .labelCheck {
  &::before {
    display: block;
  }
}

.labelCheck {
  position: relative;
  cursor: pointer;
  @include fontStyle(0.75rem, 1.5);
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.5px;

  padding-left: 2.125rem;
  max-width: 12.625rem;

  &::after {
    @include pseudo;

    width: 1.5rem;
    height: 1.5rem;

    border-radius: 0.3125rem;
    border: 1px solid #323232;
    top: 50%;
    transform: translateY(-50%);
  }

  &::before {
    @include pseudo;

    display: none;
    width: 0.875rem;
    height: 0.875rem;
    top: 50%;
    transform: translateY(-50%);
    left: 0.35rem;
    border-radius: 0.25rem;
    background: linear-gradient(180deg, #25b444 0%, #00ff19 100%);
  }
}

.ticketForm {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.formText {
  @include fontStyle(0.75rem, 1.2);
}

.formUpdateText {
  margin-top: 15px;
  @include fontStyle(0.75rem, 1.2);
  color: #dc3737;
}

.formWrap {
  margin-top: 1.125rem;
}

.radioWrapper {
  position: relative;
}

.genderTitle {
  @include fontStyle(0.75rem, 1.166);
}

.genderList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}
.tabsWrapper {
  margin-top: 1rem;
}
.tabs {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 3px;
  place-items: center;
  width: fit-content;
  margin: 0 auto;
}
.tab {
  width: 140px;
  height: 43px;
  background-color: #222222;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  outline: unset;
  border: unset;

  font-family: Gilroy;
  font-size: 14px;
  font-weight: 300;
  line-height: 16.14px;
  letter-spacing: 0.5px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;

  cursor: pointer;
}
.tabError {
  color: #c86363;
  background-color: #281514;
}
.activeTab {
  background-color: #1c2511;
}
.message {
  position: absolute;
  bottom: -1.25rem;
  font-size: 0.75rem;
  line-height: 1.125;
  letter-spacing: 0.2px;
}

.genderItem {
  @include fontStyle(0.75rem, 1.166);
  letter-spacing: 0.2px;

  &:not(:last-child) {
    margin-right: 4rem;
  }
}

.addBlock {
  display: grid;
  gap: 1.875rem;
  grid-template-columns: 56% 40%;
  align-items: center;
  margin-top: 1.625rem;
  padding-right: 2rem;
}

.deleteItemBtn {
  position: absolute;
  padding-right: 1rem;
  bottom: 3.25rem;
  right: 0;
  cursor: pointer;
  font-size: 0.5rem;
  line-height: 1.8;
  background-color: transparent;
  border: none;
  will-change: color;
  transition: color 0.2s ease;
  color: rgba(255, 255, 255, 0.4);

  &::after,
  &::before {
    @include pseudo;
    width: 0.75rem;
    height: 1px;
    top: 50%;
    left: auto;
    right: 0;
    background-color: #737373;
    will-change: background-color;
    transition: background-color 0.2s ease;
    transform: translateY(-50%);
  }

  &::after {
    transform: translateY(-50%) rotate(45deg);
  }

  &::before {
    transform: translateY(-50%) rotate(-45deg);
  }

  &:hover {
    color: rgba($color: #fff, $alpha: 1);

    &::after,
    &::before {
      background-color: white;
    }
  }
}

.addInsuranceBtn {
  position: absolute;
  padding-right: 1rem;
  bottom: -1.5rem;
  right: 0;
  cursor: pointer;
  font-size: 0.5rem;
  line-height: 1.8;
  background-color: transparent;
  border: none;
  will-change: color;
  transition: color 0.2s ease;
  color: rgba(255, 255, 255, 0.4);

  &:hover {
    color: rgba($color: #fff, $alpha: 1);

    &::after,
    &::before {
      background-color: white;
    }
  }
}

.deleteInsuranceBtn {
  position: absolute;
  padding-right: 1rem;
  bottom: -1.5rem;
  right: 0;
  cursor: pointer;
  font-size: 0.5rem;
  line-height: 1.8;
  background-color: transparent;
  border: none;
  will-change: color;
  transition: color 0.2s ease;
  color: rgba(255, 255, 255, 0.4);

  &::after,
  &::before {
    @include pseudo;
    width: 0.75rem;
    height: 1px;
    top: 50%;
    left: auto;
    right: 0;
    background-color: #737373;
    will-change: background-color;
    transition: background-color 0.2s ease;
    transform: translateY(-50%);
  }

  &::after {
    transform: translateY(-50%) rotate(45deg);
  }

  &::before {
    transform: translateY(-50%) rotate(-45deg);
  }

  &:hover {
    color: rgba($color: #fff, $alpha: 1);

    &::after,
    &::before {
      background-color: white;
    }
  }
}

/// PROMOCODE BLOCK
.promocodeBtnWrap {
  position: relative;
  display: flex;
  align-items: center;
}

.promocodeField {
  width: 15.375rem;
  min-height: 2.5rem;
  padding: 0.75rem 2.5rem;
  font-size: 0.75rem;
  line-height: 1.166;
  letter-spacing: 0.2px;
  background-color: #0e0e0e;
  border: 1px solid #626262;
  border-radius: 0.3125rem;
  color: white;

  &::placeholder {
    color: rgba($color: #fff, $alpha: 0.2);
  }
}
.promocodeWrap {
  position: relative;
}
.inputLogo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  pointer-events: none;
}

.checkLogo {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  right: 1rem;
}

// ERROR FIELD
.promocodeBtnWrapError {
  & .promocodeField {
    border-color: #855757;
  }

  & .inputLogo {
    path {
      fill: #ca3c3c;
    }
  }
}

.promocodeBtnWrapCheck {
  & .promocodeField {
    border-color: #578561;
  }

  & .inputLogo {
    path {
      fill: #80e396;
    }
  }
}

.promocodeBtn {
  width: 5.125rem;
  padding: 0;
  min-height: 2.5rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.166;
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  transition: opacity 0.25s ease;
  outline: none;

  &:hover {
    opacity: 0.6;
  }
}

.promocodeComplete {
  width: 20.5rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  margin-right: 1.875rem;
}

.message {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  @include fontStyle(0.75rem, 1.2);
  color: #c86363;
}

@media (max-width: $desktop1440) {
  .costWrap {
    width: 20%;
  }
}

@media (max-width: $tabletWidth) {
  .notesWrapper {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  .tabs {
    grid-template-columns: repeat(4, 1fr);
  }
  .item {
    &:last-child {
      border-bottom: none;
    }
  }

  .infoBlockTitle {
    margin-right: 0px;
    margin-bottom: 1rem;
    margin-left: 0px;
  }
}

@media (max-width: $mobileWidth) {
  .row {
    flex-wrap: wrap;
  }
  .datepicker {
    width: 100%;
    max-width: unset;
  }
  .tabs {
    grid-template-columns: repeat(2, 1fr);
  }
  .textFile,
  .textFileInsurance {
    max-width: none;
    width: 100%;

    min-width: 280px;
  }
  .wrapperForm {
    grid-template-columns: 1fr;
  }
  .cluster {
    max-width: none;
    width: 100%;

    min-width: 285px;
  }

  .labelFile {
    margin-bottom: 2rem;
  }

  .infoBlock {
    width: 100%;
    border-left: none;
    padding-left: 0;
    margin-top: 1rem;
  }

  .text {
    max-width: 100px;
  }

  .deleteInsuranceBtn,
  .addInsuranceBtn {
    margin-right: 40px;
  }

  .logo {
    width: 50%;
  }

  .location,
  .format {
    width: auto;
  }

  .insideItem {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(6rem, 1fr));
    padding-left: 0.5rem;

    & .count {
      margin-bottom: 0;
    }
  }

  .insideItem + .insideItem {
    margin-top: 2rem;
  }

  .insideItem > * {
    padding-top: 0;
  }

  .insideItem:last-child > * {
    padding-bottom: 0;
  }

  .location,
  .count {
    margin-right: 0;
  }

  .count {
    text-align: left;
  }

  .location + .count {
    margin-left: 0;
  }

  .location + .format {
    margin-right: 0;
  }

  .costWrap {
    display: flex;
    flex-direction: column;
    border-left: none;
    text-align: left;
    width: auto;
    padding-left: 0;
  }

  .cost {
    margin-top: auto;
  }

  .deleteItemBtn {
    bottom: auto;
    top: 1.875rem;
  }

  .promocodeBtnWrap {
    display: block;
  }

  .countText {
    justify-content: flex-start;
  }

  .promocodeField {
    width: 100%;
  }

  .inputLogo,
  .checkLogo {
    top: 0.75rem;
    transform: translateY(0);
  }

  .checkLogo {
    right: 1.5rem;
  }

  .message {
    display: none;
  }
}

.formWrap {
  display: block;
}

@media (max-width: 650px) {
  .addBlock {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-right: 0;
  }
}

@media (max-width: 420px) {
  .insuranceSelect {
    width: 140px;
    font-size: 10px;
  }
}
