@import '../../Scss/Mixins.scss';
@import '../../Scss/Variables.scss';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 65.4375rem;
  padding: 12rem 3.75rem;
  padding-right: 4.75rem;
  border-radius: 0.625rem;
  background-color: #151515;
}

.title {
  margin-bottom: 0.8rem;
  font-family: 'Gilroy', sans-serif;
  font-size: 4.25rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.description {
  margin-bottom: 2rem;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.8);
  text-align: center
}

.btn {
  @include btn40;
  margin: 0 auto;
  width: 17.5rem;

  &:first-child {
    margin-bottom: 1rem;
  }

  @include btnEventPage;
}

.popupCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 3.625rem;
  height: 3.125rem;
  background-color: #222222;
  border: none;
  border-top-right-radius: 0.625rem;
  cursor: pointer;

  &::after, &::before {
    @include pseudo;

    width: 1.5rem;
    height: 0.125rem;
    background-color: #5E5E5E;
    border-radius: 0.125rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

@media(max-width: $tabletWidth) {
  .wrapper {
    padding: 3rem;
    justify-content: center;
    height: 100%;
  }
}

@media(max-width: $mobileWidth) {
  .title {
    font-size: 2.25rem;
  }

  .btn {
    width: 100%;
    max-width: 17.5rem;
  }
}