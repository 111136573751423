@import '../../Scss/Mixins.scss';
@import '../../Scss/Variables.scss';


// SELECTOR
.selector {
  display: flex;
  padding: 0;
  max-width: 23.8125rem;
  width: 100%;
  min-height: 3.125rem;
  color: white;

  background: #0C0C0C;
  position: relative;
  border-radius: 1rem;
  border: 1px solid #323232;
  transition: border-color 0.3s ease;

  &Error {
    border-color: #855757;
  }
}

.filter {
  display: block;
  font-family: 'DIN Pro';
  font-size: 1.125rem;
  font-weight: 900;
  line-height: 1.222;
  position: absolute;
  top: 0.875rem;
  left: 1.5rem;
  pointer-events: none;
}

.message, .messageError {
  position: absolute;
  bottom: -1.25rem;
  @include fontStyle(0.75rem, 1.125);

  letter-spacing: 0.2px;
}

@media(max-width: $mobileWidth) {
  .selector {
    max-width: none;
  }
}
