.main {
  margin-bottom: 3rem;

  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.breadlist {
  display: flex;
  align-items: center;
}

.breadcrumb {
  margin-right: 0.35rem;

  &:not(:last-child) {
    &::after {
      content: '>';
      padding-left: 0.35rem;
      color: rgba($color: #ffffff, $alpha: 0.4);
      transition: color 0.3s ease;
    }
  }

  & a {
    transition: color 0.3s ease;
    color: rgba($color: #ffffff, $alpha: 0.4);
  }

  &:hover {
    a {
      color: rgba($color: #ffffff, $alpha: 0.8);
    }
  }
}

.breadcrumbActive {
  a {
    cursor: default;
    pointer-events: none;
    user-select: none;
  }

  &:hover a {
    color: rgba($color: #ffffff, $alpha: 0.4);
  }
}