@import '../Scss/Mixins.scss';
@import '../Scss/Variables.scss';

.similarWrap {
  margin-bottom: 6.25rem;
}

.offersWrap {
  margin-bottom: 5.625rem;
}

.titleWrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3.875rem;
}

.buttons {
  display: flex;
  gap: 3.5rem;
  width: 100%;
}

.title {
  @include title_main;
  margin-bottom: 1.25rem;
}

.subtitle {
  @include fontStyle(0.75rem, 1.16);
}

.link {
  @include btn40;
  @include fontStyle(0.875rem, 1.2);

  position: relative;
  width: 14.3125rem;
  padding: 0.5rem 2rem;
  border: 1px solid white;
  border-radius: 0.625rem;

  &:hover {
    & .icon {
      transform: translateY(-50%) rotate(45deg);
    }
  }
}

.linkActive {
  color: #000000;
  background-color: #ffffff;
}

.settingsLink {
  margin-left: auto;
}

.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.25rem;
  transition: transform 0.3s ease;
}

.unregEvents {
  margin-bottom: 5rem;
}

.regEvents {
  margin-bottom: 5rem;
}

.unregEvents + .regEvents {
  padding-top: 3.5rem;
  border-top: 1px solid #151515;
}

.transferEvents {
  padding-top: 3.5rem;
  border-top: 1px solid #151515;
  margin-bottom: 4rem;
}

.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4.125rem 1.5rem;
}

.item {
  position: relative;
  flex-basis: 23.75rem;
  border-radius: 0.938rem;
  background-repeat: no-repeat;
  background-position: right;
  min-width: 300px;
  max-width: 350px;
}

.label {
  font-size: 1rem;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 1.188rem;
}

.underlineTitle {
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
}

.itemWrapper {
  position: relative;
  padding: 1.563rem;
  display: flex;
  width: 80%;
  min-height: 16.75rem;
  background-color: #151515;
  border-radius: 0.938rem;
  z-index: 10;
  min-height: 300px;
}

.qrCodeWrapper {
  position: relative;
  padding: 1.563rem;
  display: flex;
  width: 80%;
  min-height: 16.75rem;
  background-color: #fff;
  border-radius: 0.938rem;
  z-index: 10;
  min-height: 300px;
}

.logoWrap {
  max-width: 76px;
  height: 62px;
  margin-bottom: 2rem;
}

.logo {
  width: 100%;
  height: auto;
}

.info {
  margin-bottom: 1.1rem;
  font-size: 0.875rem;
  font-weight: 700;
  margin-left: auto;
}

.infoTicket {
  position: absolute;
  top: 1.563rem;
  right: 1.563rem;
}

.infoTicketTeam {
  position: absolute;
  top: 3.563rem;
  right: 1.763rem;
}

.itemLabel {
  display: block;
  width: 100%;
  font-size: 0.625rem;
  font-weight: 400;
}

.qr {
  width: 50px;
  height: 50px;

  position: absolute;
  top: 80px;
  left: 0;
  cursor: pointer;

  & path {
    fill: #fff;
  }
}

.popupCloseBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.625rem;
  height: 2.125rem;
  background-color: #222222;
  border: none;
  border-top-right-radius: 0.625rem;
  cursor: pointer;

  &::after,
  &::before {
    @include pseudo;

    width: 1.5rem;
    height: 0.125rem;
    background-color: #5e5e5e;
    border-radius: 0.125rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  font-size: 0;
  border-radius: 0.938rem;
  width: 30%;
  height: 100%;
  background-image: linear-gradient(180deg, #9f76f7, #359bd2);
}

.coverImg {
  border-radius: 0.938rem;
  mix-blend-mode: normal;
  height: inherit;
  width: 100%;
  object-fit: cover;
  // object-position: -23%;
}

.btn,
.btnAwait {
  @include btn40;
  @include btnEventPage;
  @include gradientViolet;

  position: absolute;
  left: 50%;
  bottom: -1.25rem;
  transform: translateX(-50%);
  max-width: 17.25rem;
  width: 100%;
  font-size: 0.75rem;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem rgba(53, 154, 210, 0.75);
}

.btnAwait {
  background-image: linear-gradient(90deg, #ee8d1b, #c7252b);
  cursor: default;
}

.btnRegNoHover,
.btnRegHover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0s ease;
}

.btnRegHover {
  opacity: 0;
}

.btnRegistered {
  background: url('../../images/svg/icon-check.svg') 2.4rem 50%,
    linear-gradient(267.53deg, #359ad2 -0.27%, #3deb2e 100%);
  background-repeat: no-repeat;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem rgba(185, 248, 105, 0.75);
  overflow: hidden;
  transition: all 0.25s ease;

  &:hover {
    background: url('../../images/svg/icon-check.svg') 2.4rem 50%,
      linear-gradient(267.53deg, #982eeb -0.27%, #359ad2 100%);
    background-repeat: no-repeat;

    .btnRegNoHover {
      opacity: 0;
    }

    .btnRegHover {
      opacity: 1;
    }
  }
}

.btnPast {
  background: url('../../images/svg/icon-check.svg') 2.4rem 50%,
    linear-gradient(267.53deg, #359ad2 -0.27%, #eb2ec2 100%);
  background-repeat: no-repeat;
  box-shadow: 0 -0.438rem 1.875rem -0.875rem rgba(185, 248, 105, 0.75);
  overflow: hidden;
  transition: all 0.25s ease;

  &:hover {
    background: url('../../images/svg/icon-check.svg') 2.4rem 50%,
      linear-gradient(267.53deg, #982eeb -0.27%, #359ad2 100%);
    background-repeat: no-repeat;

    .btnRegNoHover {
      opacity: 0;
    }

    .btnRegHover {
      opacity: 1;
    }
  }
}

.notFoundMessage {
  @include fontStyle(1.25rem, 1.2);
  margin-bottom: 2rem;
}

.notExistBlock {
  margin-bottom: 7.5rem;
}

.notExistTitle {
  @include title_main;
  text-transform: unset;
  margin-bottom: 2.25rem;
}

.notExistLink {
  @include btn40;
  @include gradientViolet;
  font-size: 0.75rem;
  max-width: 17.25rem;
  width: 100%;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  max-width: 50rem;
  width: 100%;
  background-color: #151515;
  border-radius: 0.625rem;
}

.popupText {
  margin-bottom: 1rem;
  max-width: 25rem;
}

.btnPart {
  @include btn40;
  @include gradientCherry;
  font-size: 0.75rem;
  max-width: 17.25rem;
  width: 100%;

  background: url('../../images/svg/icon-check.svg') 2.4rem 50%,
    linear-gradient(267.53deg, #ed1c24 -0.27%, #8820b9 100%), #c7252b;
  background-repeat: no-repeat;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:hover {
    background: url('../../images/svg/icon-check.svg') 2.4rem 50%,
      linear-gradient(267.53deg, #982eeb -0.27%, #359ad2 100%);
    background-repeat: no-repeat;

    .btnRegNoHover {
      opacity: 0;
    }

    .btnRegHover {
      opacity: 1;
    }
  }
}

.closePopupBtn {
  background-color: transparent;
  border: none;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;

  @include pseudo-cross-btn;
}

.btnMain {
  @include btn30;
  @include gradientCherry;
}

@media (max-width: $desktop1200) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $tabletWidth) {
  .notExistBlock {
    margin-bottom: 5rem;
  }

  .titleWrap {
    display: block;
  }
}

@media (max-width: $mobileWidth) {
  .offersWrap {
    margin-bottom: 1.625rem;
  }

  .titleWrap {
    display: block;
  }

  .buttons {
    gap: 1rem;
  }

  .link + .settingsLink {
    width: 4.125rem;
  }

  .link {
    margin-top: 1.5rem;
  }

  .icon {
    left: 1.6rem;
  }

  .cover {
    display: none;
  }

  .list {
    display: block;
  }

  .item {
    width: 100%;
    flex-basis: auto;
    min-width: 280px;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  .itemWrapper,
  .qrCodeWrapper {
    width: 100%;
  }

  .btn {
    max-width: 15rem;
  }

  .btnRegistered {
    background-position: 1.125rem, 0;
  }

  .notExistBlock {
    margin-bottom: 3rem;
  }
}
