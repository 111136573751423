@import '../Scss/Mixins.scss';
@import '../Scss/Variables.scss';

.header {
  @include container_m;
  width: 100%;

  position: relative;
  padding: 1.25rem 1.5rem;
  margin-bottom: 1.875rem;
  display: flex;
  align-items: center;
  font-family: 'Gilroy';
  font-size: 0.75rem;

  &Open {
    background-color: black;
  }
}

.linkLogo {
  margin-right: 2.5rem;
  flex-shrink: 0;
}

.newYear {
  color: #fc5060;
}

.logo {
  width: 3.625rem;

  &White {
    @include whiteIcon;
  }
}

.linkWhite {
  color: black
}

.navigation {
  margin-right: auto;
}

.langWrap {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 5.125rem;
  min-height: 1rem;
  margin-right: 2.5rem;
  padding-left: 1.625rem;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: -0.5px;

  &::after {
    @include pseudo;
    width: 1rem;
    height: 1rem;
    background: url('../../images/svg/lang-icon.svg');
    background-size: cover;
  }

  &::before {
    @include pseudo;
    left: calc(50% + 1.5rem / 2);
    transform: translateX(-50%);
    height: 100%;
    width: 2px;
    background-color: white;
  }
}

.inputLang:checked+.headerLabel {
  color: #359AD2;
  font-weight: bold;
  cursor: default;
}

.headerLabel {
  cursor: pointer;
}

.list {
  display: flex;
  flex-wrap: wrap;
}

.item {
  margin-right: 1.25rem;
  line-height: 1.166;

  &:last-child {
    margin-right: 0;
  }
}

.cityWrap {
  display: block;
  margin: 0 2.625rem 0 auto;
}

.city {
  font-weight: 700;
}

.basketBtn {
  @include btn_grey;

  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  max-width: 9.125rem;
  width: 100%;
  margin: 0 1.5rem 0 auto;
  padding: 1rem 1.125rem;
  padding-left: 1rem;

  &White {
    @include btn_white_theme;
  }
}

.basketIcon {
  flex-shrink: 0;
  margin-right: 0.75rem;
  width: 1.125rem;

  &White {
    @include whiteIcon;
  }
}

.searchBtn {
  @include btn_grey;

  display: none;
  flex-shrink: 0;
  align-items: center;
  position: relative;
  max-width: 3rem;
  width: 100%;
  margin: 0 1.5rem 0 auto;
  padding: 1rem 1.125rem;
  padding-left: 1rem;

  &White {
    @include btn_white_theme;
  }
}

.btnWrap {
  display: flex;
  position: relative;
}

.btn {
  @include btn_grey;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &_bell::after {
    @include pseudo;
    height: 1.125rem;
    background: url('../../images/svg/bell-header.svg');
  }


  &_bell::after,
  &_auth::after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
  }

  &White {
    @include btn_white_theme;
  }
}

.authIcon {
  &White {
    @include whiteIcon;
  }
}

.notification {
  position: absolute;
  top: 0;
  right: 0;
  width: 1rem;
  height: 1rem;
  background-color: black;
  border-bottom-left-radius: 0.625rem;

  &::after {
    @include pseudo;
    top: 1px;
    left: auto;
    right: 1px;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    background-color: #D5BA5E;
  }
}

.btnMenu {
  display: none;
  position: relative;
  width: 7.125rem;
  padding: 1rem 1.25rem;
  justify-content: flex-start;
  align-items: center;
  text-align: left;

  &White {
    @include btn_white_theme;
  }
}

.burgerSpan {
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -0.125rem;
  right: 1.25rem;
  width: 1.25rem;
  height: 2px;
  background-color: white;
  transition: transform 200ms;

  &White {
    background-color: black;
  }
}

.burgerSpan1 {
  transform: translate3d(0, 0.5rem, 0)
}

.burgerSpan3 {
  transform: translate3d(0, -0.5rem, 0);
}

.btnMenu[data-state="open"]>.burgerSpan1 {
  transform: translate3d(0, 0, 0) rotate(45deg);
}

.btnMenu[data-state="open"]>.burgerSpan2 {
  transform: translate3d(0, 0, 0) scale(0.1, 1);
}

.btnMenu[data-state="open"]>.burgerSpan3 {
  transform: translate3d(0, 0, 0) rotate(-45deg);
}


.btnExit {
  width: 6rem;
  padding: 0 1rem;
}

.closeBtn {
  display: none;
  background-color: #151515;
  @include pseudo-cross-btn(white);
}

.mobSubtitle {
  display: none;
  @include fontStyle(0.875rem, 1.2);
  color: rgba($color: #fff, $alpha: 0.5);
}

.spanName {
  flex-shrink: 0;
}

.menuWrapper {
  position: relative;
}

.menu {
  display: none;
  position: absolute;
  top: 12px;
  left: -35px;
  z-index: 5;
  padding-top: 20px;
  padding-left: 10px;
  padding-top: 10px;

}

.wrapper {
  width: 110px;
  background-color: #000;
  border: 1px solid rgb(39, 39, 39);
  border-radius: 10px;
  border-top: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 1px 1px 1px black;
  padding-bottom: 20px;
}

.menuWrapper {

  &:hover {
    .menu {

      display: block;
    }
  }
}

.menu:hover {
  display: block;
}

.hoverLink {
  margin-top: 10px;
  text-align: center;
}

.hoverLink:hover {
  color: #359AD2;

}

.link[data-name="corporate"]:hover {
  color: #359AD2
}

///////// ADAPTIVE
@media (max-width: $desktop1200) {
  .navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.25rem 1.5rem;
    position: fixed;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    top: 5.625rem;
    left: 0;
    width: 100%;
    height: calc(100% - 5.625rem);
    background-color: #000;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .mobSubtitle {
    display: block;
  }

  .navigationAnimate {
    opacity: 1;
    z-index: 99;
    pointer-events: all;
  }

  .list {
    width: 100%;
    margin: 2.5rem auto 2rem;
    display: flex;
    flex-direction: column;
  }

  .item {
    @include fontStyle(2rem, 1.5625);
    font-weight: 400;
    letter-spacing: 1px;
    border-radius: 0.625rem;
    transition: background-color 0.3s ease;

    & a {
      display: block;
      transition: color 0.3s ease;
    }

    &:hover a {
      color: rgba($color: #fff, $alpha: 0.5);
    }
  }

  .closeBtn,
  .btnMenu {
    display: block;
  }

  .closeBtn {
    position: fixed;
    top: 1.25rem;
    right: 2rem;
  }

  .btn {
    &:not(:last-child) {
      margin-right: 1.25rem;
    }
  }
}

@media(max-width: $tabletWidth) {
  .item {
    @include fontStyle(1.375rem, 2.1);
  }

  .basketBtn {
    max-width: 3rem;
    padding: 0.5rem;
    margin-right: 1rem;

    &::after {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .searchBtn {
    display: inline-flex;
    max-width: 3rem;
    margin-right: 1rem;

    &::after {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .basketIcon {
    margin-right: 0;
  }

  .burgerSpan {
    right: 1rem;
    margin-top: -1px;
  }

  .spanName {
    display: none;
  }

  .btn {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .btnMenu {
    width: 3.125rem;
    padding: 0;

    &::after {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media(max-width: $smartPhoneWidth) {
  .langWrap {
    position: absolute;
    bottom: 0;
    left: 1.5rem;
    padding: 0;
    margin: 0;
    width: 4rem;

    &::after {
      display: none;
    }

    &::before {
      left: 50%;
    }
  }
}
