@import '../Scss/Mixins.scss';
@import '../Scss/Variables.scss';

.main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.profile {
  position: relative;
  max-width: 90rem;
  width: 100%;
  padding: 0 1.5rem;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
}

.firstCheckbox,
.secondCheckbox {
  /*  padding-top: 0.75rem; */
  font-weight: 300;
  color: #fff;

  font-family: Gilroy;
  font-size: 0.875rem;
  letter-spacing: 0.2px;
  line-height: 1.5;
}

.firstCheckbox {
  margin-right: 1.875rem;

  & label {
    display: block;
    max-width: 11.125rem;
  }
}

.secondCheckbox {
  & label {
    display: block;
    max-width: 14.5rem;
  }
}

.checkboxLabel {
  position: relative;
  padding-left: 2.125rem;
  cursor: pointer;
  white-space: nowrap;

  &::before {
    @include pseudo;

    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid #323232;
    border-radius: 0.3125rem;
  }

  &::after {
    @include checkbox_check;
    background-image: linear-gradient(180deg, #25b444, #00ff19);
  }
}

input[type='checkbox']:checked + .checkboxLabel {
  &::after {
    display: block;
  }
}

.aside {
  padding: 1.25rem 2.1875rem 1rem 1.5rem;
  background-color: #0d0d0d;
  border-radius: 0.625rem;
  width: 17.5rem;
}

.asideItem {
  @include fontStyle(0.875rem, 1.21);
  padding-left: 2.375rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  letter-spacing: 0.2px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: left center;
  transition: color 0.3s ease;

  /*   &:not(:last-child) {
    border-bottom: 1px solid #323232;
  } */

  &:hover {
    color: rgba($color: #fff, $alpha: 0.6);
  }
  /*  &:nth-child(1) {
    background-image: url('../../images/svg/Personal/iconTicket.svg');
  }

  &:nth-child(2) {
    background-image: url('../../images/svg/Personal/iconShopping.svg');
  }

  &:nth-child(3) {
    background-image: url('../../images/svg/Personal/iconClub.svg');
  }

  &:nth-child(4) {
    background-image: url('../../images/svg/Personal/iconSettings.svg');
  }

  &:nth-child(5) {
    background-image: url('../../images/profile/activ-icon.svg');
  } */

  &[data-state='active'] {
    font-weight: 700;
    // pointer-events: none;
  }
}

//////////// MAIN INFO ////////////
.settings {
  padding-left: 7.875rem;
  flex-grow: 1;
}

.title {
  @include title_main;

  margin-bottom: 0.5rem;
}

.subtitle {
  @include fontStyle(0.75rem, 1.166);
}

.desc {
  @include fontStyle(1rem, 1.1875);
  display: block;
  letter-spacing: 0.2px;

  max-width: 50.5rem;
  margin: 1.25rem 0 2.75rem;
}

.inputWrap {
  position: relative;
}

.inputCustomLabel {
  position: absolute;
  top: 0;
  @include fontStyle(0.75rem, 1.167);
}

// MAIN FORM
.form {
  max-width: 50rem;
  width: 100%;
}

.checkboxes {
  display: flex;
  column-gap: 40px;
}

.formsWrap {
  padding: 2.5rem;
  background-color: #0d0d0d;
  border-radius: 0.625rem;

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  &Dev {
    position: relative;
    pointer-events: none;
    user-select: none;

    &::after {
      @include pseudo;
      content: 'В РАЗРАБОТКЕ';
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      z-index: auto;
      font-size: 2rem;

      background-color: rgba($color: #000, $alpha: 0.9);
    }
  }
}

.formsWrapGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  row-gap: 1.875rem;
  column-gap: 1.25rem;
}

.fieldWrapper {
  position: relative;
  max-width: 23.75rem;
}

.changePhoneBtn {
  @include btn;

  position: absolute;
  bottom: -1.5rem;
  left: calc(100% - 5rem);
  background: none;
}

.btn {
  @include btn45;

  background: linear-gradient(to right, #359bd281, #992eeb7c);
  border-radius: 0.25rem;

  width: 100%;
  margin-top: 1.375rem;
  max-width: 280px;
}

.message {
  @include fontStyle(0.875rem, 1.2);

  align-self: center;
  color: rgba($color: #fff, $alpha: 0.6);
}

//////////// fields syles ////////////
.settingBtn {
  @include btn45;
  @include gradientViolet;

  border-radius: 0.625rem;
  min-height: 3.125rem;
  width: 100%;
  max-width: 23.75rem;
  align-self: flex-end;

  &First {
    max-width: none;
    margin-top: 1.875rem;
  }

  &Sec {
    align-self: flex-end;
  }

  &Relate {
    width: 100%;
    max-width: 21.875rem;
    margin-top: 1.875rem;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &CodeAgain {
    background: transparent;
    border: 1px solid #fff;
    grid-column: 2/3;
  }
}

.phoneChangeStatus {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0.5rem;
  top: -1.5rem;
  padding: 0.75rem;
  background-color: #000;
  z-index: 1;
  border-radius: 0.375rem;
  border-bottom-right-radius: 0;
  font-size: 0.75rem;

  &::after {
    @include pseudo;
    width: 0;
    height: 0;
    left: auto;
    top: auto;
    right: 0;
    bottom: -0.5rem;
    border: 0.5rem solid;
    border-right-width: 1.25rem;
    border-left-width: 1.25rem;
    border-color: rgb(0, 0, 0) rgb(0, 0, 0) transparent transparent;
  }
}

.warnLogo {
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
}

//////////// AUTH //////////////
.authTitle {
  @include fontStyle(0.75rem, 1.167);
  display: block;
  margin-bottom: 1.875rem;
}

.authList {
  display: flex;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  gap: 2.125rem;
  align-items: center;
}

.authItem {
  position: relative;
}

.messageAuth {
  @include fontStyle(0.625rem, 1.2);
  position: absolute;
  bottom: -1rem;
  color: rgba($color: #fff, $alpha: 0.6);
}

.authBtn,
.label {
  font-family: 'Gilroy';
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  letter-spacing: 0.2px;
  @include fontStyle(0.875rem, 1.5);
  background-color: transparent;
  color: white;
  border: none;
}

button.authBtn {
  cursor: default;
}

.input {
  width: 100%;
  padding: 1rem 3.75rem;
  max-height: 3.125rem;

  font-size: 1.25rem;
  line-height: 1.333;
  color: white;

  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: 0.2px;

  border-radius: 0.625rem;
  border: 1px solid #323232;
  background-color: transparent;
  border-radius: 1rem;
  outline: none;
  transition: border-color 0.3s ease;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
}

.label {
  position: relative;
  padding-left: 2.25rem;

  &::after,
  &::before {
    @include pseudo;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  &::after {
    width: 1rem;
    height: 1rem;
    border: 0.0625rem solid #ffffff;
  }

  &::before {
    display: none;
    left: 0.3125rem;
    width: 0.375rem;
    height: 0.375rem;
    background-color: #32ee72;
  }
}

input:checked + label::before {
  display: block;
}

input:disabled + .label {
  opacity: 0.4;
}

.authIcon,
.authIconReg {
  width: 1.625rem;
  height: 1.625rem;
  margin-right: 1.375rem;
}

.authIconReg {
  padding: 0.25rem;
  background-image: linear-gradient(90deg, #6a27d9, #2ba6b7);
  border-radius: 3px;
}

.authNote {
  @include fontStyle(0.5rem, 1.1);
  letter-spacing: 0.2px;
  color: rgba($color: #fff, $alpha: 0.6);
}

.genderTitle {
  @include fontStyle(1rem, 1.2);
}

.genderList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.25rem;
}

.genderItem {
  @include fontStyle(0.75rem, 1.166);
  letter-spacing: 0.2px;

  &:not(:last-child) {
    margin-right: 7rem;
  }
}

// HELP
.helpTitle {
  @include fontStyle(1rem, 1.19);
}

.helpSubtitle {
  @include fontStyle(0.75rem, 1.16);
  margin: 0.5rem 0 1.875rem;
}

/////// footer
.footer {
  margin-top: 6.75rem;
  padding: 1.875rem 1.5rem;
  border-top: 0.0625rem solid #151515;
  text-align: center;
}

.footerCopyright {
  @include fontStyle(0.625rem, 1.4);
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.5);
}

.one {
  display: flex;
}
.insurance {
  max-width: 280px;
}

@media (max-width: $desktop1200) {
  .settingBtn {
    &CodeAgain {
      grid-column: auto;
    }
  }
}

@media (max-width: $tabletWidth) {
  .profile {
    display: block;
    position: relative;
    padding-bottom: 5rem;
  }
  .btn {
    max-width: 100%;
  }
  .insurance {
    max-width: 100%;
  }
  .settings {
    padding-left: 0;
  }

  .asideWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  .aside {
    width: auto;
    padding: 1rem 1.25rem;
  }

  .asideList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .asideItem {
    padding: 1.5rem;
    padding-left: 2rem;

    &:not(:last-child) {
      margin-right: 1.5rem;
      border-bottom: none;
    }
  }

  .desc {
    margin-bottom: 1.75rem;
  }

  .form {
    max-width: none;
  }

  .genderList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 1rem;
  }

  .btn {
    margin-top: 0;
    // max-width: 20rem;
    position: absolute;
    bottom: 0;
    width: $adaptiveWidth;
  }
  .insurance {
    position: absolute;
    bottom: -320px;
    left: 50px;
    width: $adaptiveWidth;
    min-width: 300px;
    max-width: 500px;
  }
  .footer {
    margin-top: 4rem;
  }

  .changePhoneBtn {
    left: calc(100% - 5rem);
  }

  .settingBtn {
    &CodeAgain {
      grid-column: 2/3;
    }
  }

  .authList {
    gap: 1.75rem;
  }
}

@media (max-width: $mobileWidth) {
  .formsWrapGrid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
  .insurance {
    left: 20px;
  }
  .fieldWrapper {
    max-width: none;
  }

  .settingBtn {
    &CodeAgain {
      grid-column: auto;
      max-width: none;
    }
  }

  .authList {
    display: block;
  }

  .authItem {
    &:not(:last-child) {
      margin-bottom: 1.75rem;
    }
  }

  .checkboxes {
    flex-direction: column;
    row-gap: 40px;
    column-gap: 0;
  }

  .checkboxLabel {
    // white-space: normal;
    font-size: 13px;
  }
}
