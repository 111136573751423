@import '../../Scss/Mixins.scss';

.popup {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  top: 0; left: 0;
}

.wrapper {
  position: relative;
  width: 49rem;
  padding: 1.5rem;
  padding-left: 4.4375rem;
  background-color: #151515;
  border-radius: 1rem;
  border: 1px solid #2A2B2E;
}

.title {
  position: relative;
  padding-left: 2.5rem;
  margin-bottom: 2.375rem;

  font-family: 'DIN Pro';
  font-size: 2.25rem;
  font-weight: 900;
  line-height: 1.222;

  &::after {
    @include pseudo;

    width: 1.5rem;
    height: 1.75rem;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('../../../images/svg/checkMailPopup.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.link {
  @include btn45;
  @include gradientViolet;

  min-width: 11.25rem;
  margin-top: 2.375rem;
}

.btn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 1.25rem;
  height: 1.25rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  @include pseudo-cross-btn($r: 45deg);
}
