@import '../../../Scss/Mixins.scss';
@import '../../../Scss/Variables.scss';

.dateItem {
  &:not(:last-child) {
    padding-bottom: 3.5rem;
  }
}

.titleWrap {
  display: flex;
  margin-bottom: 2.5rem;
}

.monthTitle {
  margin-right: 0.5rem;
  @include fontStyle(1.125rem, 1.2);
  text-transform: uppercase;
}

.line {
  display: flex;
  flex-grow: 1;
  border-bottom: 1px solid #151515;
  pointer-events: none;
}

.title {
  font-family: 'DIN Pro';
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.21;
  text-transform: uppercase;

  margin-bottom: 3.75rem;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  grid-template-rows: 20rem;
  grid-auto-rows: 20rem;
  gap: 1.5rem;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  border-radius: 1rem;
  background-position: center center;
  background-size: cover;
  background-color: #0c0c0c;
}

.itemTitle {
  @include fontStyle(1.1rem, 1.2);

  font-weight: 600;
  /* text-transform: uppercase; */
  display: block;
  margin: auto 0 0.5rem;
}

.itemText {
  font-size: 1rem;
  line-height: 1.166;
  color: rgba($color: #fff, $alpha: 0.8);
  margin-bottom: 1.5rem;
}

.btnPart {
  @include btn30;
  @include gradientCherry;
  width: 100%;
  border-radius: 0.3125rem;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.btnCity {
  @include btn30;
  background-color: #465a82;
}

.logo {
  width: 4.5rem;
  height: auto;
}

.data {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  @include fontStyle(1.375rem);
  text-transform: uppercase;
}

.addActivity {
  position: relative;
  display: block;
  margin: 2.5rem auto 0;
  padding: 2.5rem 0 0;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;

  &:after {
    @include pseudo;

    left: 50%;
    transform: translateX(-50%);
    width: 1rem;
    height: 1.25rem;
    background-size: cover;
    background-image: url('../../../../images/svg/load-activity-icon.svg');
  }
}

@media (max-width: $tabletWidth) {
  .itemTitle {
    margin-bottom: 0.5rem;
  }

  .list {
    gap: 3.25rem;
  }

  .item {
    display: flex;
    flex-direction: column;
  }

  .imageWrap {
    display: none;
  }

  .logo {
    right: 1rem;
  }

  .btnWrap {
    margin-top: 1.5rem;
  }

  .btnPart {
    display: block;
    margin: 0 0 0.75rem 0;
  }
}

@media (max-width: $mobileWidth) {
  .logo {
    width: 6rem;
  }

  .data {
    font-size: 1rem;
  }

  .list {
    gap: 2.5rem;
  }

  .itemTitle {
    font-size: 1rem;
  }
}

@media (max-width: $smartPhoneWidth) {
  .item {
    padding: 1.5rem;
  }

  .logo {
    position: static;
    order: -1;
    transform: translate3d(0, 0, 0);
    top: auto;
    right: auto;
    margin-bottom: 1rem;
  }
}
