@import '../../Scss/Mixins.scss';

// Firefox scrollbar
// html, body {
//   scrollbar-color: #7128E8 #2A2B2E;
//   scrollbar-width: thin;
// }

$borderWidth: 1px;

.calendar-select {
  &__menu {
    &::after {
      @include pseudo;

      z-index: -1;
      top: -$borderWidth;
      left: -$borderWidth;
      height: calc(100% + 2px);
      width: calc(100% + 2px);
      background: linear-gradient(
        60deg,
        #f79533,
        #f37055,
        #ef4e7b,
        #a166ab,
        #5073b8,
        #1098ad,
        #07b39b,
        #6fba82
      );
      border-radius: 5px;
      animation: animatedgradient 3s ease alternate infinite;
      background-size: 300% 300%;
    }
  }

  &__menu-list {
    scrollbar-color: #4333d1 #2a2b2e;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      border-radius: 2px;
      width: 2px;
      background-color: #2a2b2e;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #31aff5, #7128e8);
      border-radius: 2px;
    }
  }
}
@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
//baskets-place-select
.baskets-place-select-container {
  z-index: 1 !important;
}

.baskets-place-select__single-value {
  margin-left: 1.2rem;
}
.baskets-place-select__placeholder {
  margin-left: 1.2rem;
}
.baskets-place-select {
  &-container {
    max-width: 23.75rem;

    & .baskets-place-select__control {
      border: 1px solid #323232;
      border-radius: 1rem;
      min-height: 3.125rem;
      padding: 0.75rem 0.625rem 0.75rem 1.5rem;
    }

    & .baskets-place-select__value-container {
      margin-top: 0;
    }

    & .baskets-place-select__menu,
    & .baskets-place-select__menu-list {
      font-weight: 400;

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #31aff5, #7128e8);
        border-radius: 2px;
      }

      &::-webkit-scrollbar {
        border-radius: 2px;
        width: 2px;
        background-color: #2a2b2e;
      }
    }
  }
}

@media (max-width: $mobileWidth) {
  .baskets-place-select {
    &-container {
      max-width: none;
    }
  }
}

//basketsize-select
.basketsize-select-container {
  z-index: 6 !important;
}

.basketsize-select__single-value {
  margin-left: 1.2rem;
}
.basketsize-select__placeholder {
  margin-left: 1.2rem;
}
.basketsize-select {
  &-container {
    max-width: 23.75rem;

    & .basketsize-select__control {
      border: 1px solid #323232;
      border-radius: 1rem;
      min-height: 3.125rem;
      padding: 0.75rem 0.625rem 0.75rem 1.5rem;
    }

    & .basketsize-select__value-container {
      margin-top: 0;
    }

    & .basketsize-select__menu,
    & .basketsize-select__menu-list {
      font-weight: 400;

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #31aff5, #7128e8);
        border-radius: 2px;
      }

      &::-webkit-scrollbar {
        border-radius: 2px;
        width: 2px;
        background-color: #2a2b2e;
      }
    }
  }
}

@media (max-width: $mobileWidth) {
  .basketsize-select {
    &-container {
      max-width: none;
    }
  }
}

.cluster-select-container {
  z-index: 3 !important;
}

.cluster-select {
  &-container {
    max-width: 23.75rem;

    & .cluster-select__control {
      border: 1px solid #323232;
      border-radius: 1rem;
      min-height: 3.125rem;
      padding: 0.75rem 0.625rem 0.75rem 1.5rem;
      height: 3.125rem;
    }

    & .cluster-select__single-value {
      position: relative;
      bottom: 3px;
    }

    & .cluster-select__menu,
    & .cluster-select__menu-list {
      font-weight: 400;

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #31aff5, #7128e8);
        border-radius: 2px;
      }

      &::-webkit-scrollbar {
        border-radius: 2px;
        width: 2px;
        background-color: #2a2b2e;
      }
    }
  }
}

@media (max-width: $mobileWidth) {
  .cluster-select {
    &-container {
      max-width: none;
    }
  }
  .size-select {
    &-container {
      max-width: none;
    }
  }
}

.calendar-event-select-container {
  z-index: 3 !important;
}

.calendar-event-select {
  &-container {
    max-width: 23.75rem;

    & .calendar-event-select__control {
      border: 1px solid #323232;
      border-radius: 1rem;
      min-height: 3.125rem;
      height: 3.125rem;
      width: 300px;
    }

    & .calendar-event-select__single-value {
      position: relative;
      bottom: 3px;
    }

    & .calendar-event-select__menu,
    & .calendar-event-select__menu-list {
      font-weight: 400;

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #31aff5, #7128e8);
        border-radius: 2px;
      }

      &::-webkit-scrollbar {
        border-radius: 2px;
        width: 2px;
        background-color: #2a2b2e;
      }
    }
  }
}

@media (max-width: $mobileWidth) {
  .calendar-event-select {
    &-container {
      max-width: none;
    }
  }
  .size-select {
    &-container {
      max-width: none;
    }
  }
}
#ticket-basket-phone {
  max-width: 292px;
}

@media (max-width: $mobileWidth) {
  #ticket-basket-phone {
    max-width: 100%;
  }
}

.ticket-select-container {
  z-index: 4 !important;
}

.ticket-select {
  &-container {
    max-width: 23.75rem;

    & .ticket-select__control {
      border: 1px solid #323232;
      border-radius: 1rem;
      width: 300px;
      min-height: 3.125rem;
      padding: 0.75rem 0.625rem 0.75rem 1.5rem;
    }

    & .ticket-select__value-container {
      margin-top: 0;
    }

    & .ticket-select__menu,
    & .ticket-select__menu-list {
      font-weight: 400;

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(180deg, #31aff5, #7128e8);
        border-radius: 2px;
      }

      &::-webkit-scrollbar {
        border-radius: 2px;
        width: 2px;
        background-color: #2a2b2e;
      }
    }
  }
}

@media (max-width: $mobileWidth) {
  .ticket-select {
    &-container {
      max-width: none;
    }
  }
}
