@import-normalize.scss;
/* normalize.scss styles */
@import "../fonts/fonts.css";
@import "./animations.css";
@import "../components/Scss/Mixins";

body {
  margin: 0;
  background-color: #000000;
  font-family: "Gilroy", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  font-style: normal;
  line-height: 1.25;
  font-weight: 400;
  color: white;

  &.white {
    background-color: white;
    color: #111113;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #31aff5, #7128e8);
    border-radius: 2px;
  }

  &::-webkit-scrollbar {
    border-radius: 2px;
    width: 2px;
    background-color: #2a2b2e;
  }
}

.common {
  @include darkThemeColors;

  /* &--light-theme {
    @include lightThemeColors;
  }

  &--dark-theme {
    @include darkThemeColors;
  } */
}

/* @media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
  .common {
    @include lightThemeColors;

    &--dark-theme {
      @include darkThemeColors;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .common {
    @include darkThemeColors;

    &--light-theme {
      @include lightThemeColors;
    }
  }
} */

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

* {
  box-sizing: border-box;
  outline: none;
}

*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
figure {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: white;
}

img {
  max-width: 100%;
  height: auto;
}
