@import '../Scss/Mixins.scss';

// Region Selector
.select-region-container , .select-city-container {
  font-size: 0.75rem !important;
  line-height: 1.116 !important;
}

.select-region-container {
  z-index: 2 !important;

  & .select-region__placeholder {
    color: white;

    & + div {
      margin: 0;
      padding: 0;
    }
  }

  .select-region__single-value {
    & + div {
      margin: 0;
      padding: 0;
    }
  }

  & .select-region__control {
    min-height: 3rem;
  }

  & .select-region__control--menu-is-open {
    & .select-region__placeholder {
      display: none;
    }
  }

  & .select-region__menu-list {
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #31AFF5, #7128E8);
      border-radius: 2px;
    }

    &::-webkit-scrollbar {
      border-radius: 2px;
      width: 2px;
      background-color: #2A2B2E;
    }
  }

  & .select-region__menu {
    padding-top: 1rem;

    &::after {
      @include pseudo;
      top: -1px; left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background: linear-gradient(-120deg, #982EEB, #359AD2);
      border-radius: inherit;
      z-index: -1;
    }
  }

  & .select-region__input {
    color: white;
  }
}

// City Selector
.select-delivery-city-container {
  & .select-delivery-city__placeholder {
    color: white;
  }

  & .select-delivery-city__control {
    min-height: 3rem;
  }

  & .select-delivery-city__menu-list {
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #31AFF5, #7128E8);
      border-radius: 2px;
    }

    &::-webkit-scrollbar {
      border-radius: 2px;
      width: 2px;
      background-color: #2A2B2E;
    }
  }

  & .select-delivery-city__menu {
    padding-top: 1rem;

    &::after {
      @include pseudo;
      top: -1px; left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background: linear-gradient(-120deg, #982EEB, #359AD2);
      border-radius: inherit;
      z-index: -1;
    }
  }
}