.error-reg {
  animation: error-reg 3s ease;
  animation-fill-mode: forwards;
  transition: border-color 0.3s ease;
}

.error-reg + label::before {
  animation: error-reg 3s ease;
  animation-fill-mode: forwards;
}

@keyframes error-reg {
  0% {
    border-color: rgb(214, 214, 214);
  }
  20% {
    border-color: rgb(48, 48, 63);
  }
  40% {
    border-color: rgb(214, 214, 214);
  }
  60% {
    border-color: rgb(48, 48, 63);
  }
  100% {
    border-color: rgb(214, 214, 214);
  }
  100% {
    border-color: rgb(214, 214, 214);
  }
}