@import '../../Scss/Variables.scss';


.centerman {
  width: 74.375rem;
  max-width: 74.375rem;
  margin: 0 auto;

  @media (max-width: $desktop1440) {
    width: 100%;
    padding: 0 1.5rem;
  }
}

.shop {
  width: 90rem;
  padding: 0 1.5rem;
  max-width: 100%;
}

@media (max-width: $mobileWidth) {
  .shop {
    padding: 0 1rem;
  }
}
