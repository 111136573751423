/* *************************************** */
/* GILROY FONT */
@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Thin'), local('Gilroy-Thin'),
        url('./Gilroy-Thin.woff2') format('woff2'),
        url('./Gilroy-Thin.woff') format('woff'),
        url('./Gilroy-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url('./Gilroy-Regular.woff2') format('woff2'),
        url('./Gilroy-Regular.woff') format('woff'),
        url('./Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('./Gilroy-Bold.woff2') format('woff2'),
        url('./Gilroy-Bold.woff') format('woff'),
        url('./Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url('./Gilroy-Light.woff2') format('woff2'),
        url('./Gilroy-Light.woff') format('woff'),
        url('./Gilroy-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url('./Gilroy-Medium.woff2') format('woff2'),
        url('./Gilroy-Medium.woff') format('woff'),
        url('./Gilroy-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
        url('./Gilroy-Extrabold.woff2') format('woff2'),
        url('./Gilroy-Extrabold.woff') format('woff'),
        url('./Gilroy-Extrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url('./Gilroy-Black.woff2') format('woff2'),
        url('./Gilroy-Black.woff') format('woff'),
        url('./Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}


/* *************************************** */
/* DIN PRO */

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Black'),
    url('./din_pro_black.woff2') format('woff2'), /* Super Modern Browsers */
    url('./din_pro_black.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('./din_pro_black.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: 900;
    font-style: normal;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Bold'),
    url('./din_pro_bold.woff2') format('woff2'), /* Super Modern Browsers */
    url('./din_pro_bold.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('./din_pro_bold.ttf') format('truetype'); /* Safari, Android, iOS */
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: local('DIN Pro Condensed Black'), local('DINPro-CondensedBlack'),
        url('DINPro-CondensedBlack.woff2') format('woff2'),
        url('DINPro-CondensedBlack.woff') format('woff'),
        url('DINPro-CondensedBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: local('DIN Pro Condensed Bold'), local('DINPro-CondensedBold'),
        url('DINPro-CondensedBold.woff2') format('woff2'),
        url('DINPro-CondensedBold.woff') format('woff'),
        url('DINPro-CondensedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: local('DIN Pro Condensed Light'), local('DINPro-CondensedLight'),
    url('DINPro-CondensedLight.woff2') format('woff2'),
    url('DINPro-CondensedLight.woff') format('woff'),
    url('DINPro-CondensedLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: local('DIN Pro Condensed Regular'), local('DINPro-CondensedRegular'),
    url('DINPro-CondensedRegular.woff2') format('woff2'),
    url('DINPro-CondensedRegular.woff') format('woff'),
    url('DINPro-CondensedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
