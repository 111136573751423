@import '../Scss/Mixins.scss';

.select-city-container,
.select-activity-container {
  font-size: 16px !important;
  line-height: 1.116 !important;
}

.select-city__input-container {
  color: white !important;
}

.select-city-container {
  z-index: 3 !important;

  & .select-city__placeholder {
    color: white;

    & + div {
      margin: 0;
      padding: 0;
    }
  }

  .select-city__single-value {
    & + div {
      margin: 0;
      padding: 0;
    }
  }

  & .select-city__control {
    min-height: 3rem;
  }

  & .select-city__control--menu-is-open {
    & .select-city__placeholder {
      display: none;
    }
  }

  & .select-city__menu {
    padding-top: 1rem;
    z-index: auto;

    &::after {
      @include pseudo;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background: linear-gradient(-120deg, #982eeb, #359ad2);
      border-radius: inherit;
      z-index: -1;
    }
  }

  & .select-city__menu-list {
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #31aff5, #7128e8);
      border-radius: 2px;
    }

    &::-webkit-scrollbar {
      border-radius: 2px;
      width: 2px;
      background-color: #2a2b2e;
    }
  }

  & .select-city__input {
    color: white;
  }
}

.select-activity-container {
  z-index: 2 !important;
  & .select-activity__placeholder {
    color: white;
  }

  & .select-activity__control {
    min-height: 3rem;
  }

  & .select-activity__menu-list {
    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #31aff5, #7128e8);
      border-radius: 2px;
    }

    &::-webkit-scrollbar {
      border-radius: 2px;
      width: 2px;
      background-color: #2a2b2e;
    }
  }

  & .select-activity__menu {
    padding-top: 1rem;
    z-index: auto;

    &::after {
      @include pseudo;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background: linear-gradient(-120deg, #982eeb, #359ad2);
      border-radius: inherit;
      z-index: -1;
    }
  }
}

// insurance

.select-insurance-activity-container {
  z-index: 3 !important;
}

.select-insurance-activity__single-value {
  margin-left: 1.2rem;
}
.select-insurance-activity__placeholder {
  margin-left: 1.2rem;
}
.select-insurance-activity {
  &-container {
    max-width: 23.75rem;

    & .select-insurance-activity__control {
      border: 1px solid #323232;
      border-radius: 15px;
      min-height: 3.125rem;
      padding: 0.75rem 0.625rem 0.75rem 1.5rem;
    }

    & .select-insurance-activity__value-container {
      margin-top: 0;
    }

    & .select-insurance-activity__control--menu-is-open {
      & .select-insurance-activity__placeholder {
        display: none;
      }
    }

    & .select-insurance-activity__menu,
    & .select-insurance-activity__menu-list {
      font-weight: 400;

      &::-webkit-scrollbar-thumb {
        background: #323232;
        border-radius: 2px;
      }

      &::-webkit-scrollbar {
        border-radius: 2px;
        width: 2px;
        background-color: #323232;
      }
    }
  }
}

.select-insurance-city-container {
  z-index: 2 !important;
}

.select-insurance-city__single-value {
  margin-left: 1.2rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 100%;
}
.select-insurance-city__placeholder {
  margin-left: 1.2rem;
}
.select-insurance-city {
  &-container {
    max-width: 800px;

    & .select-insurance-city__control {
      border: 1px solid #323232;
      border-radius: 15px;
      min-height: 3.125rem;
      padding: 0.75rem 0.625rem 0.75rem 1.5rem;
    }

    & .select-insurance-city__value-container {
      margin-top: 0;
      display: flex;
      align-items: center;
      height: 28px;
    }

    & .select-insurance-city__control--menu-is-open {
      & .select-insurance-city__placeholder {
        display: none;
      }
    }

    & .select-insurance-city__menu,
    & .select-insurance-city__menu-list {
      font-weight: 400;
      z-index: auto;
      &::-webkit-scrollbar-thumb {
        background: #2a2b2e;
        border-radius: 2px;
      }

      &::-webkit-scrollbar {
        border-radius: 2px;
        width: 2px;
        background-color: #2a2b2e;
      }
    }
  }
}

.select-insurance-format-container {
  z-index: 1 !important;
}

.select-insurance-format__single-value {
  margin-left: 1.2rem;
}
.select-insurance-format__placeholder {
  margin-left: 1.2rem;
}
.select-insurance-format {
  &-container {
    max-width: 800px;

    & .select-insurance-format__control {
      border: 1px solid #323232;
      border-radius: 15px;
      min-height: 3.125rem;
      padding: 0.75rem 0.625rem 0.75rem 1.5rem;
    }

    & .select-insurance-format__value-container {
      margin-top: 0;
    }

    & .select-insurance-format__control--menu-is-open {
      & .select-insurance-format__placeholder {
        display: none;
      }
    }

    & .select-insurance-format__menu,
    & .select-insurance-format__menu-list {
      font-weight: 400;
      z-index: auto;
      &::-webkit-scrollbar-thumb {
        background: #2a2b2e;
        border-radius: 2px;
      }

      &::-webkit-scrollbar {
        border-radius: 2px;
        width: 2px;
        background-color: #2a2b2e;
      }
    }
  }
}
