@import '../../Scss/Mixins.scss';
$borderWidth: 1px;

.setting-select {
  &-container {
    align-self: flex-end;
    padding-top: 1.875rem;
  }

  &__menu-list {
    scrollbar-color: #4333d1 #2A2B2E;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      border-radius: 2px;
      width: 2px;
      background-color: #2A2B2E;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #31AFF5, #7128E8);
      border-radius: 2px;
    }
  }

  &__value-container {
    margin-top: 0 !important;
  }
}

// @keyframes animatedgradient {
//   0% {
//     background-position: 0% 50%;
//   }
//   50% {
//     background-position: 100% 50%;
//   }
//   100% {
//     background-position: 0% 50%;
//   }
// }
