@import '../../Scss/Variables.scss';

.inputGroup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 23.75rem;
  flex-basis: 23.75rem;
  flex-grow: 1;

  &Error {
    & .input {
      border-color: #855757;
    }

    & .icon path {
      fill: #ca3c3c;
    }
  }

  &Disabled {
    pointer-events: none;
    user-select: none;

    & .input,
    .label {
      opacity: 0.5;
    }
  }

  &NotConfirmValid {
    & .input {
      border-color: #8a8626;
    }
  }

  &ConfirmValid {
    & .input {
      border-color: #578561;
    }
  }
}

.inputWrap {
  position: relative;
  width: 100%;
}

.label {
  font-size: 0.75rem;
  contain: layout;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1667;
  letter-spacing: 0.2px;
  display: inline-flex;

  &.telError {
    color: #ca3c3c;
  }
}

.label + * {
  margin-top: 1rem;
}

.firstLetterUpperCase {
  text-transform: capitalize;
}

.icon {
  pointer-events: none;
  position: absolute;
  height: auto;
  left: 1.25rem;
  top: 50%;
  transform: translateY(-50%);

  & path {
    transition: fill 0.3s ease;
  }
}

.input {
  width: 100%;
  padding: 1rem 3.75rem;
  max-height: 3.125rem;

  color: white;

  font-size: 1rem;
  line-height: 1.1875;
  letter-spacing: 0.2px;

  border: 1px solid #323232;
  border-radius: 1rem;
  background-color: transparent;
  outline: none;
  transition: border-color 0.3s ease;
}

.message {
  position: absolute;
  bottom: -16px;
  font-size: 0.75rem;
  line-height: 1.125;
  letter-spacing: 0.2px;
  color: #ca3c3c;
}

.btnViewPass {
  position: absolute;
  top: 2rem;
  right: 1.25rem;
  width: 2rem;
  height: 3rem;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

@media (max-width: $mobileWidth) {
  .label {
    font-size: 0.625rem;
  }

  .inputGroup {
    flex-basis: auto;
    max-width: none;
    width: 100%;
  }

  .input {
    font-size: 0.875rem;
    padding-left: 2.75rem;
  }

  .message {
    font-size: 0.625rem;
  }
}
