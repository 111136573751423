@import '../../Scss/Mixins.scss';

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 1.25rem;
  margin-bottom: 3.375rem;
  border-bottom: 1px solid #151515;
}

.shopLink {
  @include fontStyle(0.75rem, 1.2);
  font-weight: 700;
  text-decoration: underline;
  flex-shrink: 0;
  margin-left: 1rem;
}

.title {
  @include title_main;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 1.5rem;
}

.item {
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  align-items: flex-start;
  padding: 1.5625rem;
  min-height: 22.5rem;
  background-image: url('../../../images/basket/similar/simil1-1x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
}

.subtitle {
  @include fontStyle(1.5rem, 1.2);
  margin: auto 0 0.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

.desc {
  @include fontStyle(0.75rem, 1.166);
  margin-bottom: 1.125rem;
}

.link {
  @include btn30;
  width: 100%;
  border-radius: 0.3125rem;
}

.city {
  @include fontStyle(0.75rem);
  cursor: auto;
  margin-bottom: 0.875rem;
}


@media(max-width: 991px) {
  .item {
    min-height: 20rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .desc {
    margin-bottom: 1.5rem;
  }
}