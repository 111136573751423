@import '../Scss/Mixins.scss';
@import '../Scss/Variables.scss';

.main {
  display: flex;
  align-items: flex-start;
  margin-bottom: 7.5rem;
}
.anchorLink {
  @include btn30;
  @include gradientViolet;
  height: 40px;
  flex-shrink: 0;
}
.rekomClubs {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
  margin-left: 180px;
  overflow-x: auto;
  scrollbar-width: none;
}
.rekomClubs::-webkit-scrollbar {
  display: none;
  background: transparent;
}
.wrap {
  display: flex;
  cursor: pointer;
}
.priorityLogo {
  max-width: 72px;
  max-height: 72px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.priority {
  display: flex;
}
.firstBlock {
  width: 288px;
  height: 96px;
  background: linear-gradient(90deg, #ed1c24 0%, #8820b9 100%);
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  padding: 12px;
  display: flex;
  gap: 12px;
}
.secondBlock {
  width: 32px;
  height: 96px;
  background: linear-gradient(180deg, #ed1c24 0%, #8820b9 100%);
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
}
.textBlock {
  font-family: 'Gilroy';
  font-size: 18px;
  font-weight: 700;
  line-height: 22.28px;
  text-align: left;
}
.logo {
  min-width: 72px;
  min-height: 72px;
}
.label {
  display: inline-flex;
  background: linear-gradient(45deg, #359ad2, #982eeb);
  padding: 1px;
  border-radius: 5px;
  padding-top: 1.2px;
  padding-left: 1.3px;
}
.searchInput {
  background-color: #000000;
  border-radius: 5px;
  border: none;
  outline: none;
  width: 100%;
  padding: 0.75rem 1.5rem;
  color: white;
  padding-left: 3.75rem;
  background-image: url('../../images/svg/search.svg');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 1.25rem center;
  @include fontStyle(1rem, 1.19);
  letter-spacing: 0.2px;
}

.noClubs {
  display: flex;
  min-height: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.wrapLinkButton {
  max-width: 280px;
  min-width: 230px;
  margin-top: 20px;
}
.icon {
  opacity: 0.5;
}
.textWrapper {
  display: flex;
  margin-left: auto;
}

.text {
  font-size: 12px;
  font-weight: 400;
  line-height: 13.92px;
  cursor: pointer;
  position: relative;
  display: block;
  width: fit-content;
  user-select: none;
  display: flex;

  &::after {
    @include pseudo;

    top: 50%;
    left: 102%;
    transform: translateY(-50%);
    width: 0.68rem;
    height: 1rem;
    /*     background-image: url('../../images/svg/icon-sorting.svg'); */
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
  grid-template-rows: 20rem;
  grid-auto-rows: 20rem;
  gap: 1.5rem;
}

.btnPart {
  @include btn30;
  @include gradientCherry;
  width: 100%;
  border-radius: 0.3125rem;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.title {
  font-family: 'DIN Pro';
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 900;
  line-height: 1.222;
  text-transform: uppercase;

  margin-bottom: 0.5rem;
}

.subtitle {
  font-size: 0.75rem;
  line-height: 1.166;
  display: block;
  margin-bottom: 3.5rem;
  white-space: nowrap;
}

.filtersBlock {
  width: 17.5rem;
  flex-shrink: 0;
  margin-right: 1.5rem;
}

.mainContent {
  width: 100%;
  position: relative;
  align-self: stretch;
}

.filters {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

$borderWidth: 1px;

.selector {
  position: relative;
  border-radius: 0.3125rem;
  background-color: #0c0c0c;

  &:after {
    @include pseudo;

    top: -$borderWidth;
    left: -$borderWidth;
    height: calc(100% + #{2 * $borderWidth});
    width: calc(100% + #{2 * $borderWidth});

    background: linear-gradient(-120deg, #982eeb, #359ad2);
    border-radius: inherit;
    z-index: -1;
    background-size: 100%;
  }
}

.pseudoWrap {
  border-radius: 0.3125rem;

  &::after {
    @include pseudo;
    top: -$borderWidth;
    left: -$borderWidth;
    height: calc(100% + #{2 * $borderWidth});
    width: calc(100% + #{2 * $borderWidth});

    background: linear-gradient(-120deg, #982eeb, #359ad2);
    border-radius: inherit;
    z-index: -1;
  }
}

@media (max-width: $tabletWidth) {
  .btnPart {
    display: block;
    margin: 0 0 0.75rem 0;
  }
}

@media (max-width: $mobileWidth) {
  .main {
    display: block;
    margin-bottom: 3.5rem;
  }
  .rekomClubs {
    margin-left: 0;
  }
  .priority {
    flex-direction: column;
  }
  .subtitle {
    margin-bottom: 2rem;
  }

  .filtersBlock {
    width: 100%;
    margin-bottom: 1rem;
  }

  .list {
    gap: 2.5rem;
  }
}
@media (max-width: 370px) {
  .textWrapper {
    flex-direction: column;
    gap: 10px;
  }
}
