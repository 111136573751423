@import '../../Scss/Mixins.scss';

.titleWrap {
  margin-bottom: 3.25rem;
}

.sessionList {
  max-width: 30rem;
}

.session {
  display: flex;
  align-items: center;
  padding-bottom: 1.875rem;
  border-bottom: 0.0625rem solid #0D0D0D;

  &:not(:last-child) {
    margin-bottom: 2.8125rem;
  }
}

.wrap {
  padding-left: 1.5rem;
}

.city {
  @include fontStyle(1.375rem, 1.22);
  display: block;
  margin-bottom: 0.625rem;
  font-weight: 900;
  font-family: 'DIN Pro';
}

.pointList {
  display: flex;
}

.point {
  position: relative;
  padding: 0 0.875rem;
  @include fontStyle(0.75rem, 1.25);

  font-weight: 700;
  letter-spacing: 0.2px;


  &:first-child {
    padding-left: 0;
  }

  &:not(:last-child) {
    &::after {
      @include pseudo;
      width: 0.0625rem;
      height: 0.875rem;
      background-color: #242424;
      left: auto;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &[data-state="active"] {
    color: #5EE92D;
  }
}
