@import '../../Scss/Mixins.scss';

.wrapper {
  position: relative;
  width: 49rem;
  padding: 2.5rem;
  padding-left: 4.4375rem;
  background-color: #151515;
  border-radius: 1rem;
  border: 1px solid #2A2B2E;
}

.title {
  position: relative;
  padding-left: 2.5rem;

  font-family: 'DIN Pro';
  font-size: 2.25rem;
  font-weight: 900;
  line-height: 1.222;

  &::after {
    @include pseudo;

    width: 1.5rem;
    height: 1.75rem;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('../../../images/svg/checkMailPopup.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.btn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 1.25rem;
  height: 1.25rem;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  @include pseudo-cross-btn($r: 45deg);
}
