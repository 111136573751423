html {
    --button-event-color: #E63D44;
    --cards-event-background-color: #212121;
    --cards-event-background-image: linear-gradient(180deg, #8F65E9, #4845D3);
    --cards-event-background-image-card-main: url('../../images/event/format/formatBack-min.png'), url('../../images/event/format/formatSecBack.svg');
}


$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
$partners-border-radius: 3px;

$border-top-block: #151515;

$footer_height: 23.625rem;

$desktop1440: 1439px; // or 90 rem
$desktop1200: 1199px;
$tabletWidth: 991px;
$mobileWidth: 768px;
$smartPhoneWidth: 480px;
$adaptiveWidth: calc(100% - 3rem);