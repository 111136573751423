@import '../../Scss/Mixins.scss';

.wrap {
  &Disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.radioLabel {
  position: relative;
  cursor: pointer;
  padding-left: 1.875rem;

  &::after, &::before {
    @include pseudo;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  &::after {
    width: 1rem;
    height: 1rem;
    border: 0.0625rem solid #FFFFFF;
  }

  &::before {
    display: none;
    left: 0.3125rem;
    width: 0.375rem;
    height: 0.375rem;
    background-color: #32EE72;
  }

  &Error {
    color: #855757;

    &::after {
      border-color: #855757;
    }
  }
}
